import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { ArrowDownLeft } from "lucide-react";
import CopyToClipboard from "@/components/wallet/CopyToClipboard";

export const ReceiveICPForm: React.FC<{ principalId: string }> = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <ArrowDownLeft className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Receive ICP</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Your Principal ID</label>
            <div className="flex items-center justify-between p-3 bg-background rounded-md border">
              <CopyToClipboard />
            </div>
          </div>
          <p className="text-sm text-muted-foreground">
            Share this Principal ID with others to receive ICP tokens.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};