import { Task, TaskActivity } from "@/types/entities";
import { _getHeaders, URL_BASE } from "./shared";

export async function getTasks(): Promise<Task[]> {
  const response = await fetch(`${URL_BASE}/tasks`, {
    method: "GET",
    headers: await _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch tasks");
  }

  return response.json();
}

export async function checkTask(taskId: number): Promise<TaskActivity> {
  const response = await fetch(`${URL_BASE}/tasks/${taskId}/check`, {
    method: "GET",
    headers: await _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("negative");
  }
  return response.json();
}

export async function startTask(taskId: number): Promise<TaskActivity> {
  const response = await fetch(`${URL_BASE}/tasks/${taskId}/start`, {
    method: "POST",
    headers: await _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to start task");
  }

  return response.json();
}

export async function completeTask(taskId: number): Promise<TaskActivity> {
  const response = await fetch(`${URL_BASE}/tasks/${taskId}/complete`, {
    method: "POST",
    headers: await _getHeaders(),
  });

  try {
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }

    return response.json();
  } catch (error) {
    console.error("Error completing task", error);
    throw new Error("Failed to complete task");
  }
}
