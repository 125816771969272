import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { MessageSquare, Mic, Video } from 'lucide-react';
import { reactionOptions, ReactionOptionValue, responseOptions, ResponseOptionValue } from "@/utils/constants";
import { AgentSettings } from "@/types/entities";

type MessageType = 'text' | 'voice' | 'video';

type Settings = {
  reaction: ReactionOptionValue;
  response: ResponseOptionValue;
};

type AgentMessageSettings = {
  [key in MessageType]: Settings;
};

interface ChatSettingsCardProps {
  settings: AgentSettings["chat_settings"];
  isLoading: boolean;
  updateSettings: (settings: Partial<AgentSettings["chat_settings"]>) => void;
  isPending: boolean;
  isPersonal?: boolean;
}

export function ChatSettingsCard({
  settings: initialSettings,
  isLoading,
  updateSettings,
  isPending,
  isPersonal = false,
}: ChatSettingsCardProps) {
  const [settings, setSettings] = useState<AgentMessageSettings>({
    text: { 
      reaction: "mentions_replies_and_admin_messages", 
      response: "text" 
    },
    voice: { 
      reaction: "mentions_replies_and_admin_messages", 
      response: "voice_and_text" 
    },
    video: { 
      reaction: "mentions_replies_and_admin_messages", 
      response: "video_circle_and_text" 
    },
  });

  useEffect(() => {
    if (initialSettings) {
      setSettings({
        text: {
          reaction: initialSettings.textMessageSettings.chatTrigger as ReactionOptionValue,
          response: initialSettings.textMessageSettings.agentReplyType as ResponseOptionValue
        },
        voice: {
          reaction: initialSettings.voiceMemoSettings.chatTrigger as ReactionOptionValue,
          response: initialSettings.voiceMemoSettings.agentReplyType as ResponseOptionValue
        },
        video: {
          reaction: initialSettings.videoCircleSettings.chatTrigger as ReactionOptionValue,
          response: initialSettings.videoCircleSettings.agentReplyType as ResponseOptionValue
        }
      });
    }
  }, [initialSettings]);

  const handleSettingChange = (messageType: MessageType, settingType: 'reaction' | 'response', value: ReactionOptionValue | ResponseOptionValue) => {
    setSettings(prev => ({
      ...prev,
      [messageType]: {
        ...prev[messageType],
        [settingType]: value,
      },
    }));
  };

  const handleSave = async () => {
    updateSettings({
      textMessageSettings: {
        agentReplyType: settings.text.response,
        chatTrigger: settings.text.reaction,
      },
      voiceMemoSettings: {
        agentReplyType: settings.voice.response,
        chatTrigger: settings.voice.reaction,
      },
      videoCircleSettings: {
        agentReplyType: settings.video.response,
        chatTrigger: settings.video.reaction,
      },
    });
  };

  const renderSettingsForType = (messageType: MessageType, icon: React.ReactNode, label: string) => (
    <div className="space-y-4">
      <Label className="text-base font-semibold">{label}</Label>
      <div className="grid gap-4 sm:grid-cols-2">
        {!isPersonal && (
          <div className="space-y-2">
            <Label htmlFor={`${messageType}-reaction`} className="flex items-center space-x-2 text-sm font-medium">
              {icon}
              <span>When to react</span>
            </Label>
            <Select
              value={settings[messageType].reaction}
              onValueChange={(value) => handleSettingChange(messageType, 'reaction', value as ReactionOptionValue)}
            >
              <SelectTrigger id={`${messageType}-reaction`}>
                <SelectValue placeholder="Select when to react" />
              </SelectTrigger>
              <SelectContent>
                {reactionOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        <div className={`space-y-2 ${isPersonal ? 'sm:col-span-2' : ''}`}>
          <Label htmlFor={`${messageType}-response`} className="flex items-center space-x-2 text-sm font-medium">
            {icon}
            <span>How to respond</span>
          </Label>
          <Select
            value={settings[messageType].response}
            onValueChange={(value) => handleSettingChange(messageType, 'response', value as ResponseOptionValue)}
          >
            <SelectTrigger id={`${messageType}-response`}>
              <SelectValue placeholder="Select how to respond" />
            </SelectTrigger>
            <SelectContent>
              {responseOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="text-center py-6">
          <div className="h-10 w-full bg-secondary animate-pulse rounded" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-2xl">
      <CardHeader>
        <CardTitle>Chat Settings</CardTitle>
        <CardDescription>Control how the agent reacts and responds to different types of messages</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-6">
        {renderSettingsForType('text', <MessageSquare className="h-4 w-4" />, 'Text Messages')}
        <Separator />
        {/* {renderSettingsForType('image', <Image className="h-4 w-4" />, 'Image Uploads')}
        <Separator /> */}
        {renderSettingsForType('voice', <Mic className="h-4 w-4" />, 'Voice Messages')}
        <Separator />
        {renderSettingsForType('video', <Video className="h-4 w-4" />, 'Video Messages')}
        <div className="flex justify-end mt-4">
          <Button onClick={handleSave} disabled={isPending}>
            {isPending ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
