// atoms/profile.ts
import { atom } from "recoil";
import { ProfileStats } from "@/types/entities";
import { getAPI } from "@/api";
import { isTMA } from "@telegram-apps/sdk-react";
import { getStats } from "@/api/supabase/profiles";

// Constants
const PROFILE_KEY = "profile_stats";

// State atom for a profile
export const profileStatsState = atom<ProfileStats | null>({
  key: PROFILE_KEY,
  default: null,
});

/**
 * Fetch a profile stats based on a user key.
 * @param {string} userKey - The key of the user.
 * @return {Promise<ProfileStats | null>} The user's profile stats or null if not found.
 */
export async function fetchProfileStats(
  userKey: string
): Promise<ProfileStats | null> {
  const API = getAPI();
  console.log("fetching profile stats for user", userKey);
  const isTelegram = await isTMA();
  if (isTelegram) {
    return await getStats();
  } else {
    return await API.profile.fetchProfileStats(userKey);
  }
}
