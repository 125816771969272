import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { Player } from "@lottiefiles/react-lottie-player";
import flameStreakAnimation from "@/assets/flame-streak.json";
import { useRecoilValue } from "recoil";
import { profileStatsState } from "@/atoms/profile_stats";
import { useNavigate, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import { triggerHapticFeedback } from "@/utils/hapticFeedback";
import cashRegisterSound from "@/assets/Cash Register Purchase Sound.mp3";

const DailyStreakPage: React.FC = () => {
  const { streakCount: urlStreakCount } = useParams<{ streakCount: string }>();
  const profileStats = useRecoilValue(profileStatsState);
  const [isHighFived, setIsHighFived] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);
  const navigate = useNavigate();
  const hapticTriggered = useRef(false);

  // Use URL streak count if available
  const currentStreak = urlStreakCount
    ? parseInt(urlStreakCount)
    : profileStats?.streak || 0;

  if (!hapticTriggered.current) {
    triggerHapticFeedback();
    hapticTriggered.current = true;
  }

  // Play sound
  const playSound = () => {
    const audio = new Audio(cashRegisterSound);
    audio.play().catch((error) => {
      console.log("Audio playback failed:", error);
    });
  };

  useEffect(() => {
    playSound();
  }, []);

  const handleHighFive = () => {
    setIsHighFived(true);
    toast({
      title: "High Five!",
      description: `You're on fire! ${currentStreak} days and counting!`,
    });
    navigate("/");
    setTimeout(() => setIsHighFived(false), 2000);
  };

  useEffect(() => {
    if (showConfetti) {
      const timeoutId = setTimeout(() => {
        setShowConfetti(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showConfetti]);

  return (
    <div className="w-full min-h-screen flex items-center justify-center relative">
      {showConfetti && <Confetti />}
      <Card className="w-full max-w-md relative overflow-hidden border bg-card/50 backdrop-blur-sm">
        <CardContent className="p-6 flex flex-col items-center space-y-4 relative z-20">
          <Badge variant="secondary" className="text-lg px-4 py-2">
            Daily Streak
          </Badge>
          <div className="flex items-center justify-center space-x-1">
            <div className="w-20 h-20 flex items-center justify-end">
              <Player
                autoplay
                loop
                src={flameStreakAnimation}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="text-6xl font-bold text-card-foreground -ml-2 pt-6">
              {currentStreak}
            </div>
          </div>
          <p className="text-center text-muted-foreground">
            {currentStreak === 1 ? "day" : "days"} in a row! Keep it up!
          </p>
          <p className="text-center text-card-foreground font-semibold">
            You have earned {100 * currentStreak} points
          </p>
          <Button
            onClick={handleHighFive}
            variant="secondary"
            className="bg-primary/10 hover:bg-primary/20"
          >
            {isHighFived ? "🙌 High Fived!" : "🙌 High Five!"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default DailyStreakPage;
