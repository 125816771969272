// components/layout/SiteFooter.tsx
import { Button } from "@/components/ui/button";
import { useLoadReceipts } from "@/hooks/useLoadReceipts";
import { To, useLocation, useNavigate } from "react-router-dom";
import { triggerHapticFeedback } from "@/utils/hapticFeedback";
import { MainMenuToggle } from "./MainMenuToggle";
import { Sparkles, Wallet, LayoutDashboard, Trophy } from "lucide-react";
import { useProfileQuery } from "@/queries/profiles";
import { cn } from "@/lib/utils";


export function SiteFooter() {
  const { data: profile } = useProfileQuery();
  const location = useLocation();
  const navigate = useNavigate();

  useLoadReceipts();

  if (!profile) {
    return null;
  }

  const handleNavigation = async (path: To) => {
    triggerHapticFeedback();
    navigate(path);
  };

  return (
    <footer className="fixed bottom-0 border-t left-0 right-0 z-50 bg-background md:hidden">
      <div className="container px-6 lg:px-8 mx-auto flex h-16 max-w-screen-sm items-center justify-between">
        <Button
          variant="ghost"
          className="h-[30px] w-[30px] p-0"
          onClick={() => handleNavigation("/")}
        >
          <LayoutDashboard
            size={30}
            className={location.pathname === "/" 
              ? "text-primary" 
              : "text-muted-foreground"}
          />
        </Button>
        <Button
          variant="ghost"
          className="h-[30px] w-[30px] p-0"
          onClick={() => handleNavigation("/agents")}
        >
          <Sparkles
            size={30}
            className={cn(
              "transition-all duration-200",
              location.pathname.startsWith("/agents")
                ? "text-primary animate-pulse" 
                : "text-muted-foreground hover:text-primary/80"
            )}
          />
        </Button>
        <Button
          variant="ghost"
          className="h-[30px] w-[30px] p-0"
          onClick={() => handleNavigation("/leaderboard")}
        >
          <Trophy
            size={30}
            className={location.pathname === "/leaderboard" 
              ? "text-primary" 
              : "text-muted-foreground"}
          />
        </Button>
        <Button
          variant="ghost"
          className="h-[30px] w-[30px] p-0"
          onClick={() => handleNavigation("/wallet")}
        >
          <Wallet
            size={30}
            className={location.pathname === "/dashboard" 
              ? "text-primary" 
              : "text-muted-foreground"}
          />
        </Button>
        <MainMenuToggle />
      </div>
    </footer>
  );
}

export default SiteFooter;
