// api/index.ts
import { ICategoryAPI,IProfileAPI,ITransactionAPI,IDocumentAPI, ITelegramReceiptsAPI, ITelegramReceiptsDataAPI } from "./types";
import { JunoCategoryAPI } from "./juno/category";
import { JunoProfileAPI } from "./juno/profile";
import { JunoTransactionAPI } from "./juno/transaction";
import { JunoTelegramReceiptsAPI } from "./juno/telegram_receipts";
import { JunoTelegramReceiptsDataAPI } from "./juno/telegram_receipts_data";
import { SatelliteOptions } from "@junobuild/core";
import { Identity } from "@dfinity/agent";
import { JunoReceiptAPI } from "./juno/receipt";
import { JunoDocumentAPI } from "./juno/document";
import { EVMApi } from "./nft/evm_api";
import { updateChatSummary, getChatSummaryById } from "./supabase/agent";

interface ChatSummary {
  data_protector?: string;
}

export interface IAPI {
  profile: IProfileAPI;
  category: ICategoryAPI;
  transaction: ITransactionAPI;
  receipt: JunoReceiptAPI;
  document: IDocumentAPI;
  evm: {
    getEvmAddress: (principalId: string) => Promise<string>;
    uploadData: (docKey: string, data: object) => Promise<string>;
    mintNFT: (ethAddress: string, uploadedDataURL: string) => Promise<string>;
  };
  agent: {
    updateChatSummary: (summaryId: string, data: { data_protector: string }) => Promise<void>;
    getChatSummaryById: (id: string) => Promise<ChatSummary>;
  };
  telegramReceipts: ITelegramReceiptsAPI;
  telegramReceiptsData: ITelegramReceiptsDataAPI;
}

let customIdentity: Identity | null = null;

export function setAPICustomIdentity(identity: Identity) {
  customIdentity = identity;
}

export function getSatteliteOptions(): SatelliteOptions {
  let options: SatelliteOptions = {};

  if (customIdentity) {
    options = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      identity: customIdentity,
      satelliteId: import.meta.env.VITE_JUNO_ID as string,
    };
  }

  return options;
}

export const getAPI = (): IAPI => {
  // Return the API object with the category API
  const evmApi = new EVMApi();
  
  return {
    category: new JunoCategoryAPI(),
    profile: new JunoProfileAPI(),
    transaction: new JunoTransactionAPI(),
    receipt: new JunoReceiptAPI(),
    document: new JunoDocumentAPI(),
    evm: {
      getEvmAddress: evmApi.getEvmAddress.bind(evmApi),
      uploadData: evmApi.uploadData.bind(evmApi),
      mintNFT: evmApi.mintNFT.bind(evmApi)
    },
    agent: {
      updateChatSummary: async (summaryId: string, data: { data_protector: string }) => {
        console.log('Updating chat summary:', summaryId, data);
        return updateChatSummary(summaryId, data);
      },
      getChatSummaryById: async (id: string) => {
        return getChatSummaryById(id);
      }
    },
    telegramReceipts: new JunoTelegramReceiptsAPI(),
    telegramReceiptsData: new JunoTelegramReceiptsDataAPI(),
  };
}
