import React from "react";
import { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useChatSummariesQuery, useChatsQuery } from "@/queries/agent";
import { Skeleton } from "@/components/ui/skeleton";
import { formatDistanceToNow } from 'date-fns';
import { authState } from "@/atoms/auth";
import { useRecoilState } from "recoil";
import MemoryList from './MemoryList';


interface GroupInfo {
    type: string;
    purpose: string;
    communicationStyle: string;
}

interface KeyPlayers {
    activeParticipants: string[];
    notableContributors: string[];
    leadershipFigures: string[];
}

interface ConnectedResources {
    tools: string[];
    projects: string[];
    externalResources: string[];
}

interface Memory {
    id: number;
    agent: string;
    summary: string;
    created: string;
    context: {
        groupInfo: GroupInfo;
        keyPlayers: KeyPlayers;
        connectedResources: ConnectedResources;
    };
    data_protector?: {
        address: string;
        transaction_hash: string;
        protected_at: string;
    };
}

function parseContext(contextStr: string): Memory['context'] {
    // Extract group info
    const groupInfoMatch = contextStr.match(/Group Information:([^]*?)Key Players:/);
    const groupInfo = groupInfoMatch ? groupInfoMatch[1] : '';

    // Extract key players
    const keyPlayersMatch = contextStr.match(/Key Players:([^]*?)Connected Resources:/);
    const keyPlayers = keyPlayersMatch ? keyPlayersMatch[1] : '';

    // Extract connected resources
    const connectedResourcesMatch = contextStr.match(/Connected Resources:([^]*?)(?:Overall|$)/);
    const connectedResources = connectedResourcesMatch ? connectedResourcesMatch[1] : '';

    return {
        groupInfo: {
            type: extractValue(groupInfo, 'Type of group:'),
            purpose: extractValue(groupInfo, 'Primary purpose and goals:'),
            communicationStyle: extractValue(groupInfo, 'Communication style and group dynamics:'),
        },
        keyPlayers: {
            activeParticipants: extractList(keyPlayers, 'Active participants and their roles:'),
            notableContributors: extractList(keyPlayers, 'Notable contributors:'),
            leadershipFigures: extractList(keyPlayers, 'Leadership figures:'),
        },
        connectedResources: {
            tools: extractList(connectedResources, 'Mentioned links, tools, or platforms:'),
            projects: extractList(connectedResources, 'Related projects or initiatives:'),
            externalResources: extractList(connectedResources, 'External resources referenced:'),
        },
    };
}

function extractValue(text: string, key: string): string {
    const pattern = new RegExp(`${key}[^]*?(?=\\n\\s*-|$)`, 'i');
    const match = text.match(pattern);
    return match
        ? match[0].replace(key, '').trim().replace(/^[-*\s]+/, '').replace(/\*\*/g, '')
        : '';
}

function extractList(text: string, key: string): string[] {
    const pattern = new RegExp(`${key}[^]*?(?=\\n\\s*-|$)`, 'i');
    const match = text.match(pattern);
    if (!match) return [];

    return match[0]
        .replace(key, '')
        .split('\n')
        .map(item => item.trim().replace(/^[-*\s]+/, '').replace(/\*\*/g, ''))
        .filter(item => item.length > 0);
}

export function AgentsMemoryCard() {
    const [openStates, setOpenStates] = useState<Record<number, boolean>>({});
    const { data: summaries, isLoading: summariesLoading, error } = useChatSummariesQuery();
    const { data: chats, isLoading: chatsLoading } = useChatsQuery();
    const [showProtectModal, setShowProtectModal] = useState(false);
    const [user] = useRecoilState(authState);
    const principalId = user?.key;

    const memories: Memory[] = React.useMemo(() => {
        if (!summaries || !chats) return [];

        return summaries.map((summary) => {
            const chat = chats.find(c => c.id.toString() === summary.chat_id?.toString());

            return {
                id: summary.id,
                agent: chat?.title || '',
                summary: summary.summary
                    .replace(/^\d+\.\s*\*\*.*?:\*\*\n?/, '')
                    .replace(/\*\*\*\*\n?/, '')
                    .split(/[.!?](?:\s|$)/)[0] + '.',
                created: formatDistanceToNow(new Date(summary.created_at), {
                    addSuffix: true,
                    includeSeconds: true
                }).replace('about ', ''),
                context: parseContext(summary.context),
                data_protector: summary.data_protector ? 
                    (() => {
                        try {
                            console.log('Parsing data_protector:', summary.data_protector);
                            const parsed = JSON.parse(summary.data_protector);
                            console.log('Parsed data_protector:', parsed);
                            return parsed;
                        } catch (e) {
                            console.error('Error parsing data_protector:', e);
                            return undefined;
                        }
                    })() 
                    : undefined
            };
        });
    }, [summaries, chats]);

    if (summariesLoading || chatsLoading) {
        return (
            <Card className="w-full">
                <CardHeader className="p-4 md:p-6">
                    <CardTitle className="text-lg md:text-xl">Agents Memory</CardTitle>
                    <CardDescription className="text-sm md:text-base">
                        Important information remembered by your agents
                    </CardDescription>
                </CardHeader>
                <CardContent className="p-4 md:p-6">
                    <Skeleton className="h-[200px] md:h-[250px] w-full rounded-lg" />
                </CardContent>
            </Card>
        );
    }

    if (error) {
        return (
            <Card className="w-full">
                <CardContent className="p-4 md:p-6">
                    <p className="text-sm md:text-base text-red-500">
                        Error loading memories: {error.message}
                    </p>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="w-full">
            <CardHeader className="p-4 md:p-6">
                <CardTitle className="text-lg md:text-xl">Agents Memory</CardTitle>
                <CardDescription className="text-sm md:text-base">
                    Important information remembered by your agents
                </CardDescription>
            </CardHeader>
            <CardContent className="p-4 md:p-6">
                <MemoryList 
                    memories={memories} 
                    openStates={openStates} 
                    setOpenStates={setOpenStates} 
                    setShowProtectModal={setShowProtectModal}
                    principalId={principalId}
                />
            </CardContent>
        </Card>
    );
}