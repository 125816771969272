import React, { useEffect, useState } from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { useProfileQuery } from "@/queries/profiles";
import { evmAddressState } from "@/atoms/minting";
import ProtectButton from './ProtectButton';
import { ChevronDown, Clock, Users, Tag, Shield } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useRecoilState } from "recoil";
import remarkGfm from 'remark-gfm';
import { cn } from '@/lib/utils';
import { getAPI } from "@/api";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";

interface Memory {
    id: number;
    agent: string;
    summary: string;
    created: string;
    data_protector?: {
        address: string;
        transaction_hash: string;
        protected_at: string;
    };
    context: {
        groupInfo: {
            type: string;
            purpose: string;
            communicationStyle: string;
        };
        keyPlayers: {
            activeParticipants: string[];
            notableContributors: string[];
            leadershipFigures: string[];
        };
        connectedResources: {
            tools: string[]; 
            projects: string[];
            externalResources: string[];
        };
    };
}

interface MemoryListProps {
    memories: Memory[];
    openStates: Record<number, boolean>;
    setOpenStates: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
    setShowProtectModal: (show: boolean) => void;
    principalId?: string;
}

const MemoryList: React.FC<MemoryListProps> = ({ 
    memories, 
    openStates, 
    setOpenStates, 
    setShowProtectModal, 
    principalId 
}) => {
    const [evmState, setEvmState] = useRecoilState(evmAddressState);
    const { data: profile } = useProfileQuery();
    const API = getAPI();
    const [isEvmAddressLoading, setIsEvmAddressLoading] = useState(true);
    const [protectedData, setProtectedData] = useState<string>('');

    useEffect(() => {
        const fetchEvmAddress = async () => {
            try {
                if (principalId) {
                    console.log('Fetching EVM address for principalId:', principalId);
                    const address = await API.evm.getEvmAddress(principalId);
                    console.log('Fetched EVM address:', address);
                    setEvmState({ evmAddress: address, profileId: principalId });
                }
            } catch (error) {
                console.error("Failed to fetch EVM address:", error);
            } finally {
                setIsEvmAddressLoading(false);
            }
        };

        if (principalId && (!evmState.evmAddress || evmState.profileId !== principalId)) {
            fetchEvmAddress();
        } else {
            setIsEvmAddressLoading(false);
        }
    }, [API.evm, evmState, principalId, setEvmState]);

    return (
        <ScrollArea className="h-[600px] pr-4">
            <div className="space-y-4">
                {memories.map((memory) => (
                    <Card key={memory.id} className="p-0 overflow-hidden">
                        <Collapsible
                            open={openStates[memory.id]}
                            onOpenChange={(open) => {
                                setOpenStates(prev => ({
                                    ...prev,
                                    [memory.id]: open
                                }));
                            }}
                        >
                            <CollapsibleTrigger className="w-full">
                                <div className="flex items-center justify-between w-full hover:bg-secondary/50 p-4 transition-colors">
                                    <div className="flex-1 mr-6">
                                        <div className="flex items-center gap-2 mb-2">
                                            <Badge variant="secondary" className="text-xs">
                                                {memory.agent}
                                            </Badge>
                                            <div className="flex items-center text-xs text-muted-foreground">
                                                <Clock className="w-3 h-3 mr-1" />
                                                {memory.created}
                                            </div>
                                        </div>
                                        <p className="text-sm text-left font-medium">{memory.summary}</p>
                                    </div>
                                    <ChevronDown
                                        className={cn(
                                            "h-4 w-4 text-muted-foreground transition-transform duration-200",
                                            openStates[memory.id] && "transform rotate-180"
                                        )}
                                    />
                                </div>
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                                <div className="p-4 space-y-4 border-t">
                                    {/* Group Information */}
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between">
                                            <h4 className="font-semibold text-sm flex items-center gap-2">
                                                <Shield className="w-4 h-4" />
                                                Agent Report
                                            </h4>
                                            {memory.data_protector ? (
                                                <div className="flex items-center gap-2">
                                                    <Badge variant="success" className="text-xs">Protected</Badge>
                                                    <a 
                                                        href={`https://explorer.iex.ec/bellecour/tx/${memory.data_protector.transaction_hash}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-xs text-blue-500 hover:text-blue-700 underline"
                                                    >
                                                        View Transaction
                                                    </a>
                                                </div>
                                            ) : (
                                                <ProtectButton
                                                    groupInfo={JSON.stringify(memory.context.groupInfo)}
                                                    memoryId={memory.id}
                                                    principalId={principalId}
                                                    evmState={evmState}
                                                    isEvmAddressLoading={isEvmAddressLoading}
                                                    setShowProtectModal={setShowProtectModal}
                                                    setProtectedData={setProtectedData}
                                                    isProtected={!!memory.data_protector}
                                                />
                                            )}
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                            <div className="space-y-2">
                                                <h5 className="text-sm font-medium">Type</h5>
                                                <ReactMarkdown remarkPlugins={[remarkGfm]} className="text-sm text-muted-foreground">
                                                    {memory.context.groupInfo.type}
                                                </ReactMarkdown>
                                            </div>
                                            <div className="space-y-2">
                                                <h5 className="text-sm font-medium">Purpose</h5>
                                                <ReactMarkdown remarkPlugins={[remarkGfm]} className="text-sm text-muted-foreground">
                                                    {memory.context.groupInfo.purpose}
                                                </ReactMarkdown>
                                            </div>
                                            <div className="space-y-2">
                                                <h5 className="text-sm font-medium">Communication Style</h5>
                                                <ReactMarkdown remarkPlugins={[remarkGfm]} className="text-sm text-muted-foreground">
                                                    {memory.context.groupInfo.communicationStyle}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Key Players */}
                                    <div className="space-y-3">
                                        <h4 className="font-semibold text-sm flex items-center gap-2">
                                            <Users className="w-4 h-4" />
                                            Key Players
                                        </h4>
                                        <div className="space-y-2">
                                            {memory.context.keyPlayers.activeParticipants.map((participant, i) => (
                                                <div key={i} className="flex items-center gap-2">
                                                    <Badge variant="outline" className="text-xs">
                                                        Active
                                                    </Badge>
                                                    <ReactMarkdown
                                                        className="text-sm text-muted-foreground"
                                                        remarkPlugins={[remarkGfm]}
                                                    >
                                                        {participant}
                                                    </ReactMarkdown>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Resources */}
                                    <div className="space-y-3">
                                        <h4 className="font-semibold text-sm flex items-center gap-2">
                                            <Tag className="w-4 h-4" />
                                            Resources
                                        </h4>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="space-y-2">
                                                <h5 className="text-sm font-medium">Tools</h5>
                                                <div className="flex flex-wrap gap-2">
                                                    {memory.context.connectedResources.tools.map((tool, index) => (
                                                        <Badge key={index} variant="secondary" className="text-xs">
                                                            {tool}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="space-y-2">
                                                <h5 className="text-sm font-medium">Projects</h5>
                                                <div className="flex flex-wrap gap-2">
                                                    {memory.context.connectedResources.projects.map((project, index) => (
                                                        <Badge key={index} variant="secondary" className="text-xs">
                                                            {project}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CollapsibleContent>
                        </Collapsible>
                    </Card>
                ))}
            </div>
        </ScrollArea>
    );
};

export default MemoryList;