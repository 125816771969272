import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAgentSettings, updateAgentSettings, getGroupSettings, updateGroupSettings, getChats, getChatSummaries, getChatSummaryById } from "@/api/supabase/agent";  
import { AgentSettings, Chat } from "@/types/entities";
import { toast } from "@/components/ui/use-toast";

export const useAgentSettingsQuery = () => {
  return useQuery<AgentSettings>({
    queryKey: ["agentSettings"],
    queryFn: getAgentSettings,
  });
};

export const useUpdateAgentSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AgentSettings, Error, Partial<AgentSettings>>({
    mutationFn: updateAgentSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["agentSettings"] });
      toast({
        title: "Settings saved",
        description: "Your agent settings have been updated successfully.",
      });
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      toast({
        title: "Error",
        description: "Failed to save settings. Please try again.",
        variant: "destructive",
      });
      throw error;
    },
  });
};

export const useGroupSettingsQuery = (groupId: string) => {
  return useQuery<AgentSettings>({
    queryKey: ["groupSettings", groupId],
    queryFn: () => getGroupSettings(groupId),
    enabled: !!groupId,
  });
};

export const useUpdateGroupSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    AgentSettings,
    Error,
    { chatId: string; settings: Partial<AgentSettings> }
  >({
    mutationFn: ({ chatId, settings }) => updateGroupSettings(chatId, settings),
    onSuccess: (_, { chatId }) => {
      queryClient.invalidateQueries({ queryKey: ["groupSettings", chatId] });
      toast({
        title: "Settings saved",
        description: "Your agent settings have been updated successfully.",
      });
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      toast({
        title: "Error",
        description: "Failed to save settings. Please try again.",
        variant: "destructive",
      });
      throw error;
    },
  });
};

export const useChatsQuery = () => {
  return useQuery<Chat[]>({
    queryKey: ["chats"],
    queryFn: () => getChats(),
  });
};

export const useChatSummariesQuery = () => {
  return useQuery({
    queryKey: ["chatSummaries"],
    queryFn: getChatSummaries,
  });
};

export const useChatSummaryQuery = (id: string) => {
  return useQuery({
    queryKey: ["chatSummary", id],
    queryFn: () => getChatSummaryById(id),
    enabled: !!id,
  });
};