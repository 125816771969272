import { getAPI } from "@/api";
import { authState } from "@/atoms/auth";
import { Check, Clipboard } from "@phosphor-icons/react";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

interface CopyToClipboardProps {}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({}) => {
  const [user] = useRecoilState(authState);

  const [copied, setCopied] = useState(false);
  const [copiedEVM, setCopiedEVM] = useState(false);
  const [evmAddress, setEvmAddress] = useState<string | undefined>(undefined);

  const API = getAPI();
  
  useEffect(() => {
    const fetchEvmAddress = async () => {
      if (!user || !user?.key) {
        return;
      }
      try {
        const address = await API.evm.getEvmAddress(user?.key);
        setEvmAddress(address);
      } catch (error) {
        console.error("Error fetching EVM address:", error);
      }
    };

    fetchEvmAddress();
  }, [API.evm, user]);

  const handleCopyPrincipalID = (text: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  const handleCopyEvmAddress = (text: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedEVM(true);
        setTimeout(() => setCopiedEVM(false), 2000);
      });
    }
  };

  const formatId = (id: string) => {
    if (id.length > 10) {
      return `${id.slice(0, 10)}...${id.slice(-5)}`;
    }
    return id;
  };

  if (!user || !user.key) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="text-center">
        <p className="text-sm flex items-center">
          Bridge id: {formatId(user.key)}
          {copied ? (
            <Check className="text-green-500 h-4 w-4 inline cursor-pointer ml-1" />
          ) : (
            <Clipboard
              className="h-4 w-4 inline cursor-pointer ml-1"
              onClick={() => handleCopyPrincipalID(user.key)}
            />
          )}
        </p>
      </div>
      {evmAddress && (
        <div className="text-center">
          <p className="text-sm flex items-center">
            EVM: {formatId(evmAddress)}
            {copiedEVM ? (
              <Check className="text-green-500 h-4 w-4 inline cursor-pointer ml-1" />
            ) : (
              <Clipboard
                className="h-4 w-4 inline cursor-pointer ml-1"
                onClick={() => handleCopyEvmAddress(evmAddress)}
              />
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default CopyToClipboard;
