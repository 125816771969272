import { HttpAgent, Actor } from "@dfinity/agent";
import { idlFactory } from "@/api/nft/backend.did";
import { IEVMApi } from "../types";

const canisterId = "tkuag-tqaaa-aaaak-akvgq-cai";
const agent = new HttpAgent({ host: "https://ic0.app" });
const actor = Actor.createActor(idlFactory, { agent, canisterId });

export class EVMApi implements IEVMApi {
  async uploadImage(docKey: string, imageByteArray: Uint8Array) {
    try {
      const result = await actor.upload_image(docKey, imageByteArray);
      const imageLink = `https://tkuag-tqaaa-aaaak-akvgq-cai.raw.icp0.io/image/${result}`;
      console.log("uploadedImageURL", imageLink);
      return imageLink;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  }

  async uploadData(docKey: string, data: object) {
    try {
      const result = await actor.upload_data(docKey, JSON.stringify(data));
      const uploadedDataURL = `https://tkuag-tqaaa-aaaak-akvgq-cai.raw.icp0.io/receipt/${result}`;
      return uploadedDataURL;
    } catch (error) {
      console.error("Error uploading data:", error);
      throw error;
    }
  }

  async mintNFT(ethAddress: string, uploadedDataURL: string): Promise<string> {
    try {
      const result = await actor.mint_nft(ethAddress, uploadedDataURL, 1);
      return result as string;
    } catch (error) {
      console.error("Error minting NFT:", error);
      throw error;
    }
  }

  async getEvmAddress(principalId: string): Promise<string> {
    if (principalId) {
      try {
        const result = await actor.get_evm_address(principalId);
        if (result) {
          return result as string;
        } else {
          throw new Error("No EVM address found");
        }
      } catch (error) {
        console.error("Error fetching EVM address:", error);
        throw error;
      }
    }
    throw new Error("Invalid principalId");
  }

  async signMessage(principalId: string, message: string): Promise<string> {
    try {
      const result = await actor.sign_message(principalId, message);
      return result as string;
    } catch (error) {
      console.error("Error signing message:", error);
      throw error;
    }
  }

  async signTypedData(principalId: string, domain: any, types: any, value: any): Promise<string> {
    try {
      const result = await actor.sign_typed_data(principalId, {
        domain,
        types,
        value
      });
      if (result) {
        return result as string;
      } else {
        throw new Error("Failed to sign typed data");
      }
    } catch (error) {
      console.error("Error signing typed data:", error);
      throw error;
    }
  }
}
