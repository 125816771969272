import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Wallet, ChevronDown } from "lucide-react";
import { MessageCircle, Mic, Video, Image } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { getStats } from "@/api/supabase/profiles";
import { useProfileQuery } from "@/queries/profiles";
import { Separator } from "@/components/ui/separator";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import { getChatById, getChatStats } from "@/api/supabase/chat-stats";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { CreditPurchaseDialog } from "./CreditPurchaseDialog";
import { useToast } from "@/components/ui/use-toast";

const CreditPurchaseOptions = [
  { credits: 10, stars: 10 },
  { credits: 20, stars: 20 },
  { credits: 50, stars: 50 },
  { credits: 100, stars: 100 },
  { credits: 200, stars: 200 },
  { credits: 500, stars: 500 },
  { credits: 1000, stars: 1000 },
];

function StatsIcon({
  Icon,
  count,
  label,
}: {
  Icon: React.ElementType;
  count: number;
  label: string;
}) {
  return (
    <div className="flex items-center space-x-2 text-sm">
      <Icon size={16} />
      <span>{count}</span>
      <span>{label}</span>
    </div>
  );
}

export function PersonalAgentStatsCard() {
  const { toast } = useToast();
  const { data: profile } = useProfileQuery();
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);

  const { data: profileStats, isLoading: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  const { data: chat } = useQuery({
    queryKey: ["chat"],
    queryFn: () => getChatById("me"),
  });

  const chatId = chat?.id;

  const { data: chatStats } = useQuery({
    queryKey: ["chatStats", chatId],
    queryFn: () => (chatId ? getChatStats(chatId) : Promise.resolve(null)),
    enabled: !!chatId,
  });

  if (profileStatsLoading) {
    return (
      <Card>
        <CardContent className="text-center py-6">
          <p>Loading stats...</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Agent Stats</CardTitle>
      </CardHeader>
      <CardContent>
        {/* Balance Section */}
        <div className="flex items-center justify-between py-4">
          <div className="flex items-center space-x-2">
            <Wallet className="text-gray-500" size={20} />
            <div>
              <span className="text-sm text-gray-500 block">Balance</span>
              <span className="text-2xl font-bold">
                {profileStats?.credits || 0} credits
              </span>
            </div>
          </div>
          <>
            <Button
              size="sm"
              className="flex items-center space-x-2"
              onClick={() => setShowPurchaseDialog(true)}
            >
              <div className="w-6 h-6 flex items-center justify-center rounded-full border-2 border-white">
                <svg>...</svg>
              </div>
              <span>Buy Credits</span>
            </Button>
            <CreditPurchaseDialog
              open={showPurchaseDialog}
              onOpenChange={setShowPurchaseDialog}
              title="Buy Personal Credits with Stars"
              description="Add credits to your personal balance"
            />
          </>
        </div>
        <Separator />
        {/* Messages Accordion */}
        <Accordion type="single" collapsible>
          <AccordionItem value="messages-in">
            <AccordionTrigger className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <ChevronDown
                  size={16}
                  className="transform rotate-0 group-open:rotate-180"
                />
                <span>
                  Total Messages In: {chatStats?.total_messages_received || 0}
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="space-y-2">
                <StatsIcon
                  Icon={MessageCircle}
                  count={chatStats?.text_messages_received || 0}
                  label="text messages"
                />
                <StatsIcon
                  Icon={Image}
                  count={chatStats?.image_messages_received || 0}
                  label="image messages"
                />
                <StatsIcon
                  Icon={Mic}
                  count={chatStats?.voice_messages_received || 0}
                  label="voice messages"
                />
                <StatsIcon
                  Icon={Video}
                  count={chatStats?.video_messages_received || 0}
                  label="video messages"
                />
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="messages-out">
            <AccordionTrigger className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <ChevronDown
                  size={16}
                  className="transform rotate-0 group-open:rotate-180"
                />
                <span>
                  Total Messages Out: {chatStats?.total_messages_sent || 0}
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="space-y-2">
                <StatsIcon
                  Icon={MessageCircle}
                  count={chatStats?.text_messages_sent || 0}
                  label="text messages"
                />
                <StatsIcon
                  Icon={Image}
                  count={chatStats?.image_messages_sent || 0}
                  label="image messages"
                />
                <StatsIcon
                  Icon={Mic}
                  count={chatStats?.voice_messages_sent || 0}
                  label="voice messages"
                />
                <StatsIcon
                  Icon={Video}
                  count={chatStats?.video_messages_sent || 0}
                  label="video messages"
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </CardContent>
    </Card>
  );
}