// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { IExecDataProtector } from '@iexec/dataprotector';
import { JsonRpcProvider, Wallet } from 'ethers';
import { toast } from "@/components/ui/use-toast";
import { getAPI } from "@/api";
import iconUrl from '../../assets/partners/iExec-2.png';
import { cloudStorage } from "@telegram-apps/sdk-react";
import { Ed25519KeyIdentity } from "@dfinity/identity";
import crypto  from 'crypto-browserify';
import { useQueryClient } from '@tanstack/react-query';

declare global {
    interface Window {
        ethereum?: any;
    }
}

interface ProtectButtonProps {
    groupInfo: string;
    memoryId: number;
    principalId?: string;
    evmState: {
        evmAddress: string | undefined;
        profileId: string | undefined;
    };
    isEvmAddressLoading: boolean;
    setShowProtectModal?: (show: boolean) => void;
    setProtectedData?: (address: string) => void;
    isProtected?: boolean;
    transactionHash?: string;
}

const ProtectButton: React.FC<ProtectButtonProps> = ({
    groupInfo,
    memoryId,
    principalId,
    evmState,
    isEvmAddressLoading,
    setShowProtectModal,
    setProtectedData,
    isProtected,
    transactionHash
}) => {
    const [isProtecting, setIsProtecting] = useState(false);
    const API = getAPI();
    const IDENITITY_KEY = "telegram_internet_identity";
    const queryClient = useQueryClient();


    const handleProtect = async () => {
        if (isProtecting) return;
        setIsProtecting(true);

        try {
            if (!principalId) {
                throw new Error("Please connect your wallet first");
            }

            if (isEvmAddressLoading) {
                throw new Error("Please wait while we load your EVM address");
            }

            if (!evmState.evmAddress) {
                // Try to fetch the EVM address again if it's not available
                const address = await API.evm.getEvmAddress(principalId);
                if (!address) {
                    throw new Error("EVM address not found. Please try again later.");
                }
            }
            
            console.log('PrincipalId:', principalId);
            console.log('Using EVM address:', evmState.evmAddress);

            // Get identity from Telegram cloud storage
            console.log('Getting identity from Telegram cloud storage...');
            const restoredIdentityJson = await cloudStorage.getItem(IDENITITY_KEY);
            if (!restoredIdentityJson) {
                throw new Error("No identity found in Telegram cloud storage");
            }
            const identity = Ed25519KeyIdentity.fromJSON(restoredIdentityJson);
            console.log('Identity restored:', identity.getPrincipal().toString());

            // Create a provider and use the identity's private key
            console.log('Creating provider and wallet with stored identity...');
            const provider = new JsonRpcProvider('https://bellecour.iex.ec');
            // const privateKeyBytes = identity.getKeyPair().secretKey;
            
            // // Convert ArrayBuffer to Uint8Array and then to hex string
            // const bytes = new Uint8Array(privateKeyBytes);
            // const privateKeyHex = Array.from(bytes)
            //     .map(b => b.toString(16).padStart(2, '0'))
            //     .join('');

            const hash = crypto.createHash('sha256').update(identity.getPrincipal().toString()).digest('hex');

            // Step 2: Use the hash as a private key seed
            const privateKey = `0x${hash}`;
            const wallet = new Wallet(privateKey).connect(provider);
            console.log('Wallet created with address:', wallet.address);

            // Initialize DataProtector with the wallet from Telegram identity
            console.log('Initializing DataProtector...');
            const dataProtector = new IExecDataProtector(wallet, {
                iexecOptions: {
                    smsURL: 'https://sms.scone-prod.v8-bellecour.iex.ec',
                    resultProxyURL: 'https://result-proxy.scone-prod.v8-bellecour.iex.ec'
                },
                ipfsGateway: 'https://ipfs-gateway.v8-bellecour.iex.ec',
                ipfsNode: 'https://ipfs-upload.v8-bellecour.iex.ec'
            });
            console.log('DataProtector instance created:', dataProtector);

            try {
                // Format the data according to the DataProtector documentation
                console.log('Protecting data...');
                const protectedData = await dataProtector.core.protectData({
                    name: 'Protected Group Information',
                    data: {
                        file: JSON.stringify({
                            groupInfo: JSON.parse(groupInfo)
                        })
                    }
                });

                console.log('Data protected successfully:', protectedData);

                // Save protected data with updated format
                const dataToSave = {
                    data_protector: JSON.stringify({
                        address: protectedData.address,
                        creation_timestamp: protectedData.creationTimestamp,
                        encryption_key: protectedData.encryptionKey,
                        multiaddr: protectedData.multiaddr,
                        name: protectedData.name,
                        owner: protectedData.owner,
                        schema: protectedData.schema,
                        transaction_hash: protectedData.transactionHash,
                        protected_at: new Date().toISOString()
                    })
                };

                try {
                    console.log('Saving protected data...', {
                        memoryId,
                        dataToSave
                    });
                    const uploadResult = await API.agent.updateChatSummary(memoryId.toString(), dataToSave);
                    console.log('Upload result:', uploadResult);

                    // Verify the data was saved
                    const verifyData = await API.agent.getChatSummaryById(memoryId.toString());
                    console.log('Verify saved data:', verifyData);

                    if (!verifyData.data_protector) {
                        throw new Error('Data was not saved properly');
                    }
                } catch (error: unknown) {
                    console.error('Error saving protected data:', error);
                    throw new Error(`Failed to save protection data: ${error instanceof Error ? error.message : 'Unknown error'}`);
                }

                // Get the list of protected data to verify
                const protectedDataList = await dataProtector.core.getProtectedData({
                    owner: wallet.address
                });
                console.log('Protected data list:', protectedDataList);

                // Invalidate and refetch chat summaries
                await queryClient.invalidateQueries({ queryKey: ['chatSummaries'] });
                // console.log('Chat summaries invalidated');

                setIsProtecting(false);
                toast({
                    title: "Success",
                    description: (
                        <div className="flex flex-col gap-2">
                            <p>Data protected and saved successfully!</p>
                            <a 
                                href={`https://explorer.iex.ec/bellecour/tx/${protectedData.transactionHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:text-blue-700 underline"
                            >
                                View transaction on iExec Explorer
                            </a>
                        </div>
                    ),
                    duration: 5000
                });

                if (typeof setShowProtectModal !== 'undefined') {
                    setShowProtectModal(false);
                } else {
                    console.error('setShowProtectModal is not defined');
                }
                if (typeof setProtectedData === 'function') {
                    setProtectedData(protectedData.address);
                } else {
                    console.error('setProtectedData is not a function');
                }
            } catch (error: unknown) {
                console.error('Error protecting data:', error);
                throw error;
            }
        } catch (error: unknown) {
            if (error instanceof Error && error.message?.includes('Failed to call sign_with_ecdsa')) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: 'Not enough cycles to perform the operation. Please try again later or contact support.',
                    duration: 5000,
                });
            } else {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: error instanceof Error ? error.message : 'Error protecting data',
                    duration: 5000,
                });
            }
            console.error('Error details:', {
                principalId,
                evmAddress: evmState.evmAddress,
                error: error instanceof Error ? error.message : 'Unknown error'
            });
        } finally {
            setIsProtecting(false);
        }
    };

    return (
        <Button
            variant="outline"
            size="sm"
            className="h-7 text-xs flex items-center gap-2"
            onClick={handleProtect}
            disabled={isEvmAddressLoading || isProtecting || isProtected}
        >
            <img 
                src={iconUrl} 
                alt="RLC Logo" 
                style={{ width: 16, height: 16 }} 
            />
            {isEvmAddressLoading 
                ? "Loading..." 
                : isProtecting 
                    ? "Protecting..." 
                    : isProtected
                        ? "Protected"
                        : "Protect"}
        </Button>
    );
};


export default ProtectButton;