// atoms/transaction.ts
import { atom } from "recoil";
import { Transaction } from "@/types/entities";
import { getAPI } from "@/api";
import { isTMA } from "@telegram-apps/sdk-react";
import {
  getTransaction as supabaseGetTransaction,
  getTransactions as supabaseGetTransactions,
  createTransaction as supabaseCreateTransaction,
  updateTransaction as supabaseUpdateTransaction,
  deleteTransaction as supabaseDeleteTransaction,
} from "@/api/supabase/transactions";

// State atom for a single transaction
export const transactionState = atom<Transaction | null>({
  key: "singleTransactionState",
  default: null,
});

// State atom for a list of transactions
export const transactionsState = atom<Transaction[]>({
  key: "allTransactionsState",
  default: [],
});

const API = getAPI();

/**
 * Fetch a transaction based on a transaction key.
 * @param {string} transactionKey - The key of the transaction.
 * @return {Promise<Transaction | null>} The transaction or null if not found.
 */
export async function fetchTransaction(
  transactionKey: string
): Promise<Transaction | null> {
  const isTelegram = await isTMA();
  try {
    return isTelegram
      ? await supabaseGetTransaction(transactionKey)
      : await API.transaction.fetchOne(transactionKey);
  } catch (error) {
    console.error("Failed to fetch transaction:", error);
    return null;
  }
}

/**
 * Create a new transaction and update the Recoil state.
 * @param {Transaction} data - The transaction data to set.
 * @param {Function} setTransactions - Function to update the transactions state.
 * @param {string} [transactionKey] - Optional transaction key to set.
 * @return {Promise<Transaction>} The transaction data that was set.
 */
export async function createTransaction(
  data: Transaction,
  setTransactions: (
    callback: (transactions: Transaction[]) => Transaction[]
  ) => void,
  transactionKey?: string
): Promise<Transaction> {
  try {
    const isTelegram = await isTMA();
    const transaction = isTelegram
      ? await supabaseCreateTransaction(data, transactionKey)
      : await API.transaction.create(data, transactionKey);

    setTransactions((prevTransactions: Transaction[]) => [
      ...prevTransactions,
      transaction,
    ]);

    return transaction;
  } catch (error) {
    console.error("Failed to create transaction:", error);
    throw error;
  }
}

/**
 * Update an existing transaction and update the Recoil state.
 * @param {Transaction} data - The transaction data to update.
 * @param {string} transactionKey - The key of the transaction.
 * @param {Function} setTransactions - Function to update the transactions state.
 * @return {Promise<Transaction>} The updated transaction data.
 */
export async function updateTransaction(
  data: Transaction,
  transactionKey: string,
  setTransactions: (
    callback: (transactions: Transaction[]) => Transaction[]
  ) => void
): Promise<Transaction> {
  try {
    const isTelegram = await isTMA();
    const transaction = isTelegram
      ? await supabaseUpdateTransaction(transactionKey, data)
      : await API.transaction.update(transactionKey, data);

    setTransactions((prevTransactions: Transaction[]) =>
      prevTransactions.map((transaction) =>
        transaction.id === transactionKey
          ? { ...data, id: transactionKey }
          : transaction
      )
    );

    return transaction;
  } catch (error) {
    console.error("Failed to update transaction:", error);
    throw error;
  }
}

/**
 * Fetch all transactions.
 * @return {Promise<Transaction[]>} List of all transactions.
 */
export async function fetchAllTransactions(): Promise<Transaction[]> {
  try {
    const isTelegram = await isTMA();
    return isTelegram
      ? await supabaseGetTransactions()
      : await API.transaction.fetchAll();
  } catch (error) {
    console.error("Failed to fetch all transactions:", error);
    return [];
  }
}

/**
 * Delete a transaction and update the Recoil state.
 * @param {string} transactionKey - The key of the transaction.
 * @param {Function} setTransactions - Function to update the transactions state.
 * @return {Promise<{ success: boolean; message: string }>} The result of the deletion operation.
 */
export async function deleteTransaction(
  transactionKey: string,
  setTransactions: (
    callback: (transactions: Transaction[]) => Transaction[]
  ) => void
): Promise<{ success: boolean; message: string }> {
  try {
    const isTelegram = await isTMA();
    const response = isTelegram
      ? await supabaseDeleteTransaction(transactionKey)
      : await API.transaction.delete(transactionKey);
    if (response.success) {
      console.log("Deleted transaction with key:", transactionKey);
      setTransactions((prevTransactions: Transaction[]) =>
        prevTransactions.filter(
          (transaction) => transaction.id !== transactionKey
        )
      );
      console.log("Deleted transaction with key:", response);
    }
    return response;
  } catch (error) {
    console.error("Failed to delete transaction:", error);
    return {
      success: false,
      message: `Failed to delete transaction with key ${transactionKey}.`,
    };
  }
}
