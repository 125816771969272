import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PersonalTab } from "@/components/agents/PersonalTab";
import { GroupsTab } from "@/components/agents/GroupsTab";
import PageWrapper from "@/components/layout/PageWrapper";

export default function AgentsPage() {
  return (
    <PageWrapper>
      <div className="flex flex-col items-center justify-center text-center w-full mb-6">
        <h1 className="text-3xl md:text-4xl font-extrabold tracking-tight mb-2">
          Agents
        </h1>
        <p className="text-sm md:text-base font-medium text-muted-foreground max-w-md">
          Manage your personal AI agents and group interactions
        </p>
      </div>

      <div className="mt-6 md:mt-8">
        <Tabs defaultValue="personal" className="w-full">
          <TabsList className="w-full grid grid-cols-2 bg-muted/50 rounded-lg p-1">
            <TabsTrigger 
              value="personal" 
              className="rounded-md text-sm ring-offset-background transition-all hover:bg-background hover:text-foreground data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
            >
              Personal
            </TabsTrigger>
            <TabsTrigger 
              value="groups" 
              className="rounded-md text-sm ring-offset-background transition-all hover:bg-background hover:text-foreground data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
            >
              Groups
            </TabsTrigger>
          </TabsList>
          <div className="mt-6 md:mt-8">
            <TabsContent value="personal" className="space-y-4 md:space-y-6">
              <PersonalTab />
            </TabsContent>
            <TabsContent value="groups" className="space-y-4 md:space-y-6">
              <GroupsTab />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </PageWrapper>
  );
}
