import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
// import { nanoid } from "nanoid";
import { v4 as uuidv4 } from "uuid";
import { authState } from "@/atoms/auth";
import { Receipt, Transaction } from "@/types/entities";
import { createTransaction, transactionsState } from "@/atoms/transaction";
import { createReceipt, receiptsState } from "@/atoms/receipt";
import { useNavigate } from "react-router-dom";
import { assetsState, createAsset } from "@/atoms/receipt_asset";
// import { processFile } from "./fileProcessing";
import { useToast } from "@/components/ui/use-toast";
import { fetchProfileStats, profileStatsState } from "@/atoms/profile_stats";
import { isTMA } from "@telegram-apps/sdk-react";
import { ingestReceipt } from "@/api/supabase/receipts";

export const useAnalyzeReceipt = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const setReceipts = useSetRecoilState(receiptsState);
  const setTransactions = useSetRecoilState(transactionsState);
  const setAssets = useSetRecoilState(assetsState);
  const [user] = useRecoilState(authState);
  const setProfileStats = useSetRecoilState(profileStatsState);
  const navigate = useNavigate();
  const { toast } = useToast();

  const startAnalysis = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      setError("No file selected");
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      setError("File size should not exceed 10 MB");
      return;
    }

    if (!user) {
      setError("User not authenticated");
      return;
    }

    setError(null);
    setIsLoading(true);
    setIsAnalyzing(false);

    try {
      const docKey = uuidv4();

      console.time("Uploading asset");
      const assetUploadPromise = createAsset(file, setAssets, docKey);
      console.timeEnd("Uploading asset");

      // if (!assetUpload.success) {
      //   setError(assetUpload.message);
      //   return;
      // }

      const mimeType = file.type;
      console.log("MIME type of the file:", mimeType);
      const fileArrayBuffer = await file.arrayBuffer();
      let binary = "";
      const bytes = new Uint8Array(fileArrayBuffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64String = btoa(binary);
      const dataUrl = `data:${mimeType};base64,${base64String}`;

      if (await isTMA()) {
        try {
          const result = await ingestReceipt(dataUrl);
          console.log("TMA ingest receipt result", result);
          toast({
            title: "Upload successful!",
            description:
              "Your receipt has been uploaded and recognized succesfully.",
            variant: "default",
          });
          const updatedStats = await fetchProfileStats(user.key);
          // debugger;
          if (updatedStats) {
            setProfileStats(updatedStats);
          }

          // Only navigate to daily streak page if streak increased
          if (result.streakIncreased) {
            navigate(`/daily-streak/${result.streakCount}`);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.error(
            "We failed to recognize your receipt. Please try taking another picture",
            error
          );
          toast({
            title: "Error",
            description:
              "We failed to recognize your receipt. Please try taking another picture",
            variant: "destructive",
          });
        }
      } else {
        await oldAnalyzeReceipt(docKey, user.key, dataUrl);
      }

      await assetUploadPromise;

      // Clear the error state after successful upload
      setError(null);
    } catch (error) {
      console.error(
        "We failed to recognize your receipt. Please try taking another picture",
        error
      );
      setError(
        "We failed to recognize your receipt. Please try taking another picture."
      );
    } finally {
      setIsAnalyzing(false);
      setIsLoading(false);
    }
  };

  async function oldAnalyzeReceipt(
    docKey: string,
    userKey: string,
    dataUrl: string
  ) {
    const formData = new FormData();
    formData.append("imageUrl", dataUrl);

    console.time("Analyzing receipt");
    const recognitionResponse = await fetch(
      "https://lvaiubu8y6.execute-api.us-east-1.amazonaws.com/dev",
      {
        method: "POST",
        body: formData,
      }
    );
    console.timeEnd("Analyzing receipt");

    const analysisResult = await recognitionResponse.json();

    if (!analysisResult.contains_receipt) {
      setError(
        "The receipt was not recognized. AI thinks that there is no receipt in the image."
      );
      return;
    }

    const newReceipt: Receipt = {
      ...analysisResult.receipt,
      source: "receipt_upload",
    };
    console.log("newReceipt", newReceipt);

    await createReceipt(newReceipt, setReceipts, docKey);

    const now = new Date();
    const localOffset = now.getTimezoneOffset();

    const transaction: Transaction = {
      date: analysisResult.receipt.date,
      name: analysisResult.receipt.store_name,
      transaction_type: "expense",
      is_recurring: false,
      amount_cents: Math.round(analysisResult.receipt.total_price * 100),
      amount_currency: analysisResult.receipt.locale.currency,
      category: analysisResult.receipt.main_category,
      // receipt_id: docKey,
      profile_id: userKey,
      offset: localOffset * 60,
    };

    // TODO: could be awaited but who cares
    // await createTransaction(transaction, setTransactions, docKey);

    toast({
      title: "Upload successful!",
      description: "Your receipt has been uploaded successfully.",
    });

    console.log("Upload success");

    // Fetch updated profile stats
    console.log("Fetching updated profile stats...");
    const updatedStats = await fetchProfileStats(userKey);
    if (updatedStats) {
      console.log("Updated profile stats:", updatedStats);

      // Calculate the new total_items count
      const newTotalItems =
        updatedStats.total_items +
        newReceipt.line_items.reduce((total, item) => total + item.quantity, 0);
      const updatedProfileStats = {
        ...updatedStats,
        total_items: newTotalItems,
      };
      console.log(
        "Updating profile stats with new total_items:",
        updatedProfileStats
      );

      // Update the Recoil state with the new profile stats
      setProfileStats(updatedProfileStats);
    } else {
      console.log("Failed to fetch updated profile stats.");
    }
  }

  return {
    startAnalysis,
    isLoading,
    isAnalyzing,
    error,
  };
};
