import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { completeTask, startTask } from "@/api/supabase/tasks";
import { useIsTMA } from "@/hooks/useIsTMA";
import { postEvent } from "@telegram-apps/sdk-react";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { Task } from "@/types/entities";
import { useQueryClient } from "@tanstack/react-query";

export const useShareStory = () => {
  const { toast } = useToast();
  const { isTma } = useIsTMA();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [storyBonusClaimed, setStoryBonusClaimed] = useState<boolean>(false);

  const postStory = async (task: Task) => {
    if (!isTma) {
      toast({
        title: "Access Denied",
        description: "This feature is only available within Telegram.",
      });
      return;
    }

    setIsLoading(true);

    try {
      const launchParams = retrieveLaunchParams();
      const storyImageURL =
        "https://n5isd-iaaaa-aaaal-adtra-cai.icp0.io/documents/2.png";

      const shareText =
        "Unlock New Economic Opportunities with AI Agents Fine-Tuned on First-Party Data. Bridge23 offers a simple tool to collect and track your data, set privacy levels, and fine-tune your personal AI agent";

      if (shareText.length > 2048) {
        throw new Error("Share text exceeds maximum length.");
      }

      const userId = launchParams.initData?.user?.id;
      const storyWidgetLink = {
        url: `https://t.me/bridge23bot/bridge23app?startapp=inv_${userId}`,
        name: "Join The Revolution",
      };

      // Validate widget_link
      const widgetUrl = new URL(storyWidgetLink.url);
      if (widgetUrl.protocol !== "http:" && widgetUrl.protocol !== "https:") {
        throw new Error("Widget link URL protocol is invalid.");
      }
      if (storyWidgetLink.name && storyWidgetLink.name.length > 48) {
        throw new Error("Widget link name exceeds maximum length.");
      }

      const shareParams = {
        widget_link: storyWidgetLink,
        text: shareText,
      };

      await postEvent("web_app_share_to_story" as any, {
        media_url: storyImageURL,
        ...shareParams,
      });

      // Start and complete the task sequentially
      await startTask(task.id);
      await completeTask(task.id);
      setStoryBonusClaimed(true);

      // Invalidate the task query to refetch the updated task
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      queryClient.invalidateQueries({ queryKey: ["rewards"] });

      toast({
        title: "Success!",
        description: `You earned ${task.points} points! Thanks for sharing your story!`,
      });
    } catch (error) {
      console.error("Error sharing to story:", error);
      const errorMessage =
        (error as Error).message ||
        "Error sharing to story. Please try again later.";
      toast({
        title: "Error",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    postStory,
    isLoading,
    storyBonusClaimed,
  };
};
