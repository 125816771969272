import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import CopyToClipboard from "./CopyToClipboard";

interface TotalBalanceCardProps {
  totalUSDBalance: number;
}

export const TotalBalanceCard: React.FC<TotalBalanceCardProps> = ({
  totalUSDBalance,
}) => {
  return (
    <Card className="w-full bg-card">
      <CardHeader className="items-center justify-between space-y-0 pb-0">
        <CardTitle className="text-2xl text-center text-card-foreground">
          Total Balance
        </CardTitle>
      </CardHeader>
      <CardContent className="text-center">
        <p className="text-2xl font-bold text-card-foreground">
          $ {totalUSDBalance.toFixed(2)}
        </p>
        <div className="flex flex-col items-center mt-2">
          <CopyToClipboard />
        </div>
      </CardContent>
    </Card>
  );
};

export default TotalBalanceCard; 