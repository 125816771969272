import { useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import PageWrapper from "../layout/PageWrapper";
import { TonConnectUIProvider, useTonAddress } from "@tonconnect/ui-react";
import { fetchProfileStats } from "@/atoms/profile_stats";
import { fetchRewards } from "@/atoms/rewards";
import { useQuery } from "@tanstack/react-query";
import { useProfileQuery } from "@/queries/profiles";
import TelegramAuthProvider from "./TelegramAuthProvider";
import { SIWEAuthProvider } from "./SIWEAuthProvider.tsx";

export default function PrivyAuthProvider({
  isTMA,
  children,
}: {
  isTMA: boolean;
  children: React.ReactNode;
}) {
  const [isInitialized, setIsInitialized] = useState(false);
  const { ready, authenticated, login, user, getAccessToken } = usePrivy();

  const { data: profile, isPending: profileLoading } =
    useProfileQuery(isInitialized);

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
    enabled: isInitialized,
  });

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => fetchProfileStats(profile?.id || "anonymous"),
    enabled: isInitialized,
  });

  async function getToken() {
    const token = await getAccessToken();
    console.log("Got privy token", token);

    const response = await fetch(import.meta.env.VITE_API_URL_BASE + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Privy ${token}`,
      },
      body: JSON.stringify({}),
    });

    const data = await response.json();
    console.log("Login response", data);

    setIsInitialized(true);
  }

  useEffect(() => {
    if (ready) {
      if (authenticated) {
        getToken();
      } else {
        login();
      }
    }
  }, [ready, authenticated]);

  if (!ready || !isInitialized) {
    return null;
  }

  if (ready && !authenticated) {
    return <>Please login</>;
  }

  return <SIWEAuthProvider>{children}</SIWEAuthProvider>;
}
