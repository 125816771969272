import {useAgentSettingsQuery, useUpdateAgentSettingsMutation } from "@/queries/agent";
import { PersonalAgentStatsCard } from "./PersonalAgentStatsCard";
import { ChatSettingsCard } from "./ChatSettingCard";
import { AgentSettingsCard } from "./AgentSettingsCard";
import { Skeleton } from "@/components/ui/skeleton";
import { AgentSettings } from "@/types/entities";
import { AgentsMemoryCard } from "./AgentsMemoryCard";
import AgentsIntegrationCard from "./AgentsIntegrationCard";

export function PersonalTab() {
  const { data: settings, isLoading } = useAgentSettingsQuery();
  const { mutate: updateSettings, isPending } = useUpdateAgentSettingsMutation();

  if (isLoading || !settings) {
    return (
      <div className="space-y-4 md:space-y-6">
        <Skeleton className="h-[120px] md:h-[150px] w-full rounded-lg" />
        <Skeleton className="h-[200px] md:h-[250px] w-full rounded-lg" />
        <Skeleton className="h-[300px] md:h-[350px] w-full rounded-lg" />
        <Skeleton className="h-[150px] md:h-[200px] w-full rounded-lg" />
      </div>
    );
  }

  const updateChatSettings = (newChatSettings: Partial<AgentSettings["chat_settings"]>) => {
    updateSettings({
      ...settings,
      chat_settings: {
        ...settings.chat_settings,
        ...newChatSettings,
      },
    });
  };

  const updateAgentSettings = (newAgentSettings: Partial<AgentSettings["agent_settings"]>) => {
    updateSettings({
      ...settings,
      agent_settings: {
        ...settings.agent_settings,
        ...newAgentSettings,
      },
    });
  };

  return (
    <div className="space-y-4 md:space-y-6">
      <PersonalAgentStatsCard />
      <div className="grid md:grid-cols-2 gap-4 md:gap-6">
        <ChatSettingsCard
          settings={settings.chat_settings}
          isLoading={isLoading}
          updateSettings={updateChatSettings}
          isPending={isPending}
          isPersonal={true}
        />
        <AgentSettingsCard
          settings={settings.agent_settings}
          isLoading={isLoading}
          updateSettings={updateAgentSettings}
          isPending={isPending}
        />
      </div>
      <AgentsMemoryCard />
      <AgentsIntegrationCard />
    </div>
  );
}