import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { MessageCircle, Mic, Video, Link, File, Image, Send, Users, ArrowLeft } from "lucide-react";
import { useState } from "react";
import { useChatsQuery, useGroupSettingsQuery, useUpdateGroupSettingsMutation } from "@/queries/agent";
import { AgentSettings, Chat, StatsIconProps } from "@/types/entities";
import PageWrapper from "@/components/layout/PageWrapper";
import { Skeleton } from "@/components/ui/skeleton";
import { ChatSettingsCard } from "@/components/agents/ChatSettingCard";
import { AgentSettingsCard } from "@/components/agents/AgentSettingsCard";
import { DEFAULT_AGENT_SETTINGS } from "@/utils/constants";
import { getChatById } from "@/api/supabase/chat-stats";
import { useQuery } from "@tanstack/react-query";
import { CreditPurchaseDialog } from "@/components/agents/CreditPurchaseDialog";

export default function GroupAgentSettingsPage() {
  const navigate = useNavigate();

  const { groupId } = useParams();

  const { data: chatStats } = useQuery({
    queryKey: ["chat"],
    queryFn: () => getChatById(groupId || ""),
  });

  const { data: agentSettings, isLoading: isLoadingAgentSettings } =
    useGroupSettingsQuery(groupId || "");
  const { data: chats, isLoading: isLoadingChats } = useChatsQuery();
  console.log("Chats are: ", chats);
  const chat =
    chats && chats.length > 0
      ? chats.find((chat) => chat.id.toString() === groupId)
      : {
          id: "",
          title: "",
          members_count: 0,
          credits: 0,
          hasAdminAccess: false,
          stats: {
            messages: 0,
            voiceMemos: 0,
            videoCircles: 0,
            links: 0,
            files: 0,
            images: 0,
          },
        };

  const { mutate: updateGroupSettingsMutate, isPending } =
    useUpdateGroupSettingsMutation();
  const [pendingSettings, setPendingSettings] = useState<AgentSettings | null>(
    null
  );

  const settings: AgentSettings = {
    agent_settings: {
      ...DEFAULT_AGENT_SETTINGS,
      ...(pendingSettings?.agent_settings ||
        agentSettings?.agent_settings ||
        {}),
    },
    chat_settings: {
      textMessageSettings: {
        agentReplyType:
          pendingSettings?.chat_settings?.textMessageSettings?.agentReplyType ||
          agentSettings?.chat_settings?.textMessageSettings?.agentReplyType ||
          "default",
        chatTrigger:
          pendingSettings?.chat_settings?.textMessageSettings?.chatTrigger ||
          agentSettings?.chat_settings?.textMessageSettings?.chatTrigger ||
          "",
      },
      voiceMemoSettings: {
        agentReplyType:
          pendingSettings?.chat_settings?.voiceMemoSettings?.agentReplyType ||
          agentSettings?.chat_settings?.voiceMemoSettings?.agentReplyType ||
          "default",
        chatTrigger:
          pendingSettings?.chat_settings?.voiceMemoSettings?.chatTrigger ||
          agentSettings?.chat_settings?.voiceMemoSettings?.chatTrigger ||
          "",
      },
      videoCircleSettings: {
        agentReplyType:
          pendingSettings?.chat_settings?.videoCircleSettings?.agentReplyType ||
          agentSettings?.chat_settings?.videoCircleSettings?.agentReplyType ||
          "default",
        chatTrigger:
          pendingSettings?.chat_settings?.videoCircleSettings?.chatTrigger ||
          agentSettings?.chat_settings?.videoCircleSettings?.chatTrigger ||
          "",
      },
    },
  };

  const updateChatSettings = (
    newChatSettings: Partial<AgentSettings["chat_settings"]>
  ) => {
    const updatedSettings = {
      ...settings,
      chat_settings: {
        ...settings.chat_settings,
        ...newChatSettings,
      },
    };

    setPendingSettings(updatedSettings);

    updateGroupSettingsMutate({
      chatId: groupId || "",
      settings: updatedSettings,
    });
  };

  const updateAgentSettings = (
    newAgentSettings: Partial<AgentSettings["agent_settings"]>
  ) => {
    updateGroupSettingsMutate({
      chatId: groupId || "",
      settings: {
        ...settings,
        agent_settings: {
          ...settings.agent_settings,
          ...newAgentSettings,
        },
      },
    });
  };

  if (isLoadingChats || isLoadingAgentSettings || !settings || !chat) {
    return (
      <PageWrapper>
        <div className="flex justify-center items-center h-screen">
          <Skeleton className="h-10 w-full" />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <div className="border-white rounded-lg">
        <Button
          onClick={() => navigate("/agents?tab=groups")}
          className="mt-[-10%]"
          variant="ghost"
        >
          <ArrowLeft className="mr-2" size={16} /> Back to Groups
        </Button>
        <h2 className="text-2xl font-bold text-center">{chat?.title}</h2>
        <h3 className="text-lg text-muted-foreground text-center">
          Group Agent Settings
        </h3>
        <div className="space-y-4 mt-4">
          <GroupAgentStatsCard chat={chat} />
          <ChatSettingsCard
            settings={settings.chat_settings}
            isLoading={isLoadingAgentSettings}
            updateSettings={updateChatSettings}
            isPending={isPending}
          />
          <AgentSettingsCard
            settings={settings.agent_settings}
            isLoading={isLoadingAgentSettings}
            updateSettings={updateAgentSettings}
            isPending={isPending}
          />
        </div>
      </div>
    </PageWrapper>
  );
}

function GroupAgentStatsCard({ chat }: { chat: Chat }) {
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
  return (
    <Card>
      <CardHeader>
        <CardTitle>Group Agent Stats</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-2">Number of members: {chat.members_count}</p>
        <div className="flex justify-between mb-4">
          <div className="flex space-x-4">
            <StatsIcon Icon={MessageCircle} count={chat.stats?.messages || 0} />
            <StatsIcon Icon={Mic} count={chat.stats?.voiceMemos || 0} />
            <StatsIcon Icon={Video} count={chat.stats?.videoCircles || 0} />
            <StatsIcon Icon={Link} count={chat.stats?.links || 0} />
            <StatsIcon Icon={File} count={chat.stats?.files || 0} />
            <StatsIcon Icon={Image} count={chat.stats?.images || 0} />
            <StatsIcon Icon={MessageCircle} count={chat.stats?.messages || 0} />
            <StatsIcon Icon={Mic} count={chat.stats?.voiceMemos || 0} />
            <StatsIcon Icon={Video} count={chat.stats?.videoCircles || 0} />
            <StatsIcon Icon={Link} count={chat.stats?.links || 0} />
            <StatsIcon Icon={File} count={chat.stats?.files || 0} />
            <StatsIcon Icon={Image} count={chat.stats?.images || 0} />
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div>Chat Credits: {chat.credits}</div>
          <Button size="sm" onClick={() => setShowPurchaseDialog(true)}>
            Buy more chat credits
          </Button>
          <CreditPurchaseDialog
            open={showPurchaseDialog}
            onOpenChange={setShowPurchaseDialog}
            title="Buy Group Credits with Stars"
            description="Group credits are separate from your main balance"
          />
        </div>
        <p className="text-sm text-gray-500">
          Note: Group chat credits are a separate balance from the main balance.
        </p>
      </CardContent>
    </Card>
  );
}

function StatsIcon({ Icon, count, isSent = false }: StatsIconProps) {
  return (
    <div className="flex items-center">
      <Icon className="mr-1" style={{ width: 16, height: 16 }} />
      <span className="text-sm">{count}</span>
      {isSent && <Send className="ml-1" style={{ width: 12, height: 12 }} />}
    </div>
  );
}

function GroupChatSettingsCard({
  settings,
  onUpdate,
}: {
  settings: AgentSettings["chat_settings"];
  onUpdate: (settings: Partial<AgentSettings>) => void;
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Chat Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <h3 className="font-semibold mb-2">Text Messages</h3>
          <div className="space-y-2">
            <label className="block">Chat Trigger:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="mentions-replies">
                  Mentions and Replies
                </SelectItem>
                <SelectItem value="mentions-replies-admin">
                  Mentions and Replies + All Admin User Messages
                </SelectItem>
                <SelectItem value="all-messages">All Messages</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2 mt-2">
            <label className="block">Agent Replies With:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="text">Text</SelectItem>
                <SelectItem value="voice-text">Voice and Text</SelectItem>
                <SelectItem value="circle-text">Circle and Text</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <h3 className="font-semibold mb-2">Voice Memos</h3>
          <div className="space-y-2">
            <label className="block">Chat Trigger:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin-messages">
                  All Admin User Messages
                </SelectItem>
                <SelectItem value="all-messages">All Messages</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2 mt-2">
            <label className="block">Agent Replies With:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="voice-text">Voice and Text</SelectItem>
                <SelectItem value="video-circle-text">
                  Video Circle and Text
                </SelectItem>
                <SelectItem value="only-video-circle">
                  Only Video Circle
                </SelectItem>
                <SelectItem value="only-voice">Only Voice</SelectItem>
                <SelectItem value="only-text">Only Text</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <h3 className="font-semibold mb-2">Circle Videos</h3>
          <div className="space-y-2">
            <label className="block">Chat Trigger:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin-messages">
                  All Admin User Messages
                </SelectItem>
                <SelectItem value="all-messages">All Messages</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2 mt-2">
            <label className="block">Agent Replies With:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="voice-text">Voice and Text</SelectItem>
                <SelectItem value="video-circle-text">
                  Video Circle and Text
                </SelectItem>
                <SelectItem value="only-video-circle">
                  Only Video Circle
                </SelectItem>
                <SelectItem value="only-voice">Only Voice</SelectItem>
                <SelectItem value="only-text">Only Text</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button>Save Changes</Button>
        </div>
      </CardContent>
    </Card>
  );
}

function GroupAgentSettingsCard({
  settings,
  onUpdate,
}: {
  settings: AgentSettings["agent_settings"] | undefined;
  onUpdate: (settings: Partial<AgentSettings>) => void;
}) {
  const [mainGoal, setMainGoal] = useState(settings?.mainGoal || "general");
  const [temperature, setTemperature] = useState(settings?.temperature || 0.5);

  const handleUpdate = () => {
    onUpdate({
      agent_settings: {
        mainGoal,
        temperature,
        language: settings?.language ?? "English",
        customGoalPrompt: settings?.customGoalPrompt ?? "",
        knowledgeBase: settings?.knowledgeBase,
      },
    });
  };

  if (!settings) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Agent Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <label className="block mb-2">Language:</label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Select language" />
            </SelectTrigger>
            <SelectContent>
              {languages.map((lang) => (
                <SelectItem key={lang} value={lang.toLowerCase()}>
                  {lang}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block mb-2">Main goal:</label>
          <Select value={mainGoal} onValueChange={setMainGoal}>
            <SelectTrigger>
              <SelectValue placeholder="Select main goal" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="general">General Chat</SelectItem>
              <SelectItem value="finance">Finance</SelectItem>
              <SelectItem value="nutrition">Nutrition and Fitness</SelectItem>
              <SelectItem value="custom">Custom</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {mainGoal === "custom" && (
          <Textarea placeholder="Enter custom prompt" />
        )}
        <div>
          <label className="block mb-2">
            Temperature: {temperature.toFixed(1)}
          </label>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={[temperature]}
            onValueChange={([value]) => setTemperature(value)}
          />
          <p className="text-sm text-gray-500 mt-2">
            Controls how creative vs focused the AI responses will be. Higher
            values (closer to 1.0) result in more varied and creative responses,
            while lower values (closer to 0.0) make responses more focused and
            deterministic.
          </p>
        </div>
        <div>
          <label className="block mb-2">Knowledge base:</label>
          <p className="text-sm text-gray-500">Coming Soon</p>
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={handleUpdate}>Save Changes</Button>
        </div>
      </CardContent>
    </Card>
  );
}

const languages = [
  "Afrikaans",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Belarusian",
  "Bosnian",
  "Bulgarian",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Finnish",
  "French",
  "Galician",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Marathi",
  "Maori",
  "Nepali",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Welsh",
];
