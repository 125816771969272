import { isTMA } from "@telegram-apps/sdk-react";
import { useEffect, useState } from "react";
import JunoAuthProvider from "./JunoAuthProvider";
import TelegramAuthProvider from "./TelegramAuthProvider";
import { PrivyProvider } from "@privy-io/react-auth";
import PrivyAuthProvider from "./PrivyAuthProvider";

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    async function detectAndSetIsTelegramMiniApp() {
      const _isTMA = await isTMA();
      setIsTelegramMiniApp(_isTMA);
    }
    detectAndSetIsTelegramMiniApp();
  }, []);

  if (isTelegramMiniApp === null) {
    return;
  }

  return (
    <PrivyProvider appId={import.meta.env.VITE_PRIVY_APP_ID}>
      <PrivyAuthProvider isTMA={isTelegramMiniApp}>
        {children}
      </PrivyAuthProvider>
    </PrivyProvider>
  );
}
