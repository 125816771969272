// constants.ts
export const DEFAULT_AGENT_STATS = {
  total_messages_received: 0,
  total_messages_sent: 0,
  last_message_received_at: "",
  last_message_sent_at: "",
  text_messages_received: 0,
  text_messages_sent: 0,
  image_messages_received: 0,
  image_messages_sent: 0,
  voice_messages_received: 0,
  voice_messages_sent: 0,
  video_messages_received: 0,
  video_messages_sent: 0,
};

export enum AgentGoal {
  GeneralChat = "general_chat",
  Finance = "finance",
  NutritionAndFitness = "nutrition_and_fitness",
  Custom = "custom",
}

export const DEFAULT_AGENT_SETTINGS = {
  language: "English",
  mainGoal: AgentGoal.GeneralChat,
  temperature: 0.5,
};

export const reactionOptions = [
  { value: "mentions_replies_and_admin_messages", label: "Mentions and Replies + All Admin Messages" },
  { value: "mentions_replies", label: "Mentions and Replies" },
  { value: "admin_messages", label: "All Admin Messages" },
  { value: "all_messages", label: "All Messages" }
] as const;

export type ReactionOptionValue = typeof reactionOptions[number]['value'];
export const responseOptions = [
  { value: "text", label: "Text message" },
  { value: "voice_and_text", label: "Voice and Text" },
  { value: "video_circle_and_text", label: "Video Circle and Text" },
  { value: "only_voice", label: "Only Voice" },
  { value: "only_video_circle", label: "Only Video Circle" },
  { value: "none", label: "Do not respond" },
] as const;

export type ResponseOptionValue = typeof responseOptions[number]['value'];