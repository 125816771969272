import React, { useState, useEffect } from "react";
import PageWrapper from "@/components/layout/PageWrapper";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { useRecoilValue } from "recoil";
import { fetchProfileStats } from "@/atoms/profile_stats";
import { authState } from "@/atoms/auth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createAgent } from "@dfinity/utils";
import { AccountIdentifier, LedgerCanister } from "@dfinity/ledger-icp";
import { IcrcLedgerCanister } from "@dfinity/ledger-icrc";
import { Principal } from "@dfinity/principal";
import { cloudStorage } from "@telegram-apps/sdk-react";
import { Ed25519KeyIdentity } from "@dfinity/identity";
import { isTMA } from "@telegram-apps/sdk-react";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { fetchRewards } from "@/atoms/rewards";
import { useProfileQuery } from "@/queries/profiles";
import { getStats } from "@/api/supabase/profiles";
import CountUp from "react-countup";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { StatsCard } from "@/components/stats/StatsCard";
import { TokenBalanceCard } from "@/components/wallet/TokenBalanceCard";
import { TotalBalanceCard } from "@/components/wallet/TotalBalanceCard";

const IC_MAINNET_URL = "https://icp0.io/";
const MAINNET_CANISTER_ID = "ryjl3-tyaaa-aaaaa-aaaba-cai";
const BUD_CANISTER_ID = "acuhp-niaaa-aaaaj-azuba-cai";
const TONX_API_KEY = "f9925474-d700-4397-b16f-0c97cae0518d";

const fetchTONBalance = async (address: string) => {
  if (!address) return "0";

  const response = await fetch(
    `https://api.tonxapi.com/v2/account/getInfo?address=${address}`,
    {
      headers: {
        Authorization: `Bearer ${TONX_API_KEY}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch TON balance");
  }

  const data = await response.json();
  return data.balance ? (Number(data.balance) / 1e9).toFixed(4) : "0";
};

const Wallet: React.FC = () => {
  const user = useRecoilValue(authState);
  const [isTma, setIsTma] = useState(false);
  const [icpBalance, setIcpBalance] = useState<string>("0");
  const [budBalance, setBudBalance] = useState<string>("0");
  const [loading, setLoading] = useState(true);
  const [identity, setIdentity] = useState<Ed25519KeyIdentity | null>(null);
  const [totalUSDBalance, setTotalUSDBalance] = useState<number>(0);
  const IDENITITY_KEY = "telegram_internet_identity";
  const [tonConnectUI] = useTonConnectUI();
  const queryClient = useQueryClient();

  const { data: profile } = useProfileQuery();

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
  });

  const getIdentity = async () => {
    const restoredIdentity = await cloudStorage.getItem(IDENITITY_KEY);

    let identity: Ed25519KeyIdentity;
    if (!restoredIdentity) {
      console.log("generating identity");
      identity = await Ed25519KeyIdentity.generate();
      await cloudStorage.setItem(
        IDENITITY_KEY,
        JSON.stringify(identity.toJSON())
      );
      return identity;
    } else {
      identity = Ed25519KeyIdentity.fromJSON(restoredIdentity);
      return identity;
    }
  };

  const calculateUSDBalance = async (icpAmount: number) => {
    try {
      const response = await fetch(
        "https://api.coinbase.com/v2/exchange-rates?currency=ICP"
      );
      const data = await response.json();
      const icpToUsd = parseFloat(data.data.rates.USD);
      const usdBalance = icpAmount * icpToUsd;
      setTotalUSDBalance(usdBalance);
    } catch (error) {
      console.error("Error fetching ICP price:", error);
    }
  };

  console.log("Loaded identity", identity);

  const WalletContent: React.FC = () => {
    const [identity, setIdentity] = useState<Ed25519KeyIdentity | undefined>(
      undefined
    );
    const [isTma, setIsTma] = useState(false);
    const [tonConnectUI] = useTonConnectUI();
    const queryClient = useQueryClient();

    useEffect(() => {
      async function detectAndSetIsTMA() {
        const _isTMA = await isTMA();
        setIsTma(_isTMA);
      }
      detectAndSetIsTMA();
    }, []);

    useEffect(() => {
      getIdentity().then(setIdentity);
    }, []);

    const { data: tonBalance = "0", isLoading: tonBalanceLoading } = useQuery({
      queryKey: ["tonBalance", tonConnectUI.account?.address],
      queryFn: () => fetchTONBalance(tonConnectUI.account?.address || ""),
      enabled: !!tonConnectUI.account?.address,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    });

    const { data: icpBalanceData, isLoading: icpLoading } = useQuery({
      queryKey: ["icpBalance", user?.key],
      queryFn: async () => {
        if (!user?.key) return "0";
        const identity = await getIdentity();

        const agent = await createAgent({
          identity: identity,
          host: IC_MAINNET_URL,
        });

        const ledger = LedgerCanister.create({
          agent,
          canisterId: Principal.fromText(MAINNET_CANISTER_ID),
        });

        const accountId = AccountIdentifier.fromPrincipal({
          principal: Principal.fromText(user.key),
        });

        const balance = await ledger.accountBalance({
          accountIdentifier: accountId,
          certified: true,
        });

        const icpBalanceNum = Number(balance.toString()) / 100000000;
        return icpBalanceNum.toFixed(4);
      },
      enabled: !!user?.key,
      staleTime: 30000,
      refetchInterval: 30000,
    });

    const { data: budBalanceData, isLoading: budLoading } = useQuery({
      queryKey: ["budBalance", user?.key],
      queryFn: async () => {
        if (!user?.key) return "0";
        const identity = await getIdentity();

        const agent = await createAgent({
          identity: identity,
          host: IC_MAINNET_URL,
        });

        const ledger = IcrcLedgerCanister.create({
          agent,
          canisterId: Principal.fromText(BUD_CANISTER_ID),
        });

        const balance = await ledger.balance({
          owner: Principal.fromText(user.key),
          certified: true,
        });

        return (Number(balance.toString()) / 100000000).toFixed(4);
      },
      enabled: !!user?.key,
      staleTime: 30000,
      refetchInterval: 30000,
    });

    return (
      <div className="grid gap-4 md:gap-6">
        <TotalBalanceCard totalUSDBalance={totalUSDBalance} />
        {isTma && (
          <TokenBalanceCard
            type="TON"
            balance={tonBalance}
            isLoading={tonBalanceLoading}
            showTonConnect={true}
          />
        )}
        <TokenBalanceCard
          type="BUD"
          balance={budBalanceData || "0"}
          isLoading={budLoading}
          principalId={user?.key}
          identity={identity}
          onTransferComplete={() => {
            queryClient.invalidateQueries({ queryKey: ["budBalance"] });
          }}
        />
        <TokenBalanceCard
          type="ICP"
          balance={icpBalanceData || "0"}
          isLoading={icpLoading}
          principalId={user?.key}
          identity={identity}
          onTransferComplete={() => {
            queryClient.invalidateQueries({ queryKey: ["icpBalance"] });
          }}
        />
      </div>
    );
  };

  const StatsSection: React.FC = () => {
    return (
      <StatsCard
        credits={profileStats?.credits}
        streak={profileStats?.streak}
        isLoading={profileStatsLoading || rewardsLoading}
      />
    );
  };

  const PortfolioCard: React.FC = () => {
    const [portfolioData, setPortfolioData] = useState({
      totalPurchases: profileStats?.total_items || 0,
      browserActivity: "Coming Soon",
      socialMediaActivity: "Coming Soon",
      appActivity: "Coming Soon",
    });

    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Data Portfolio Overview</CardTitle>
          <CardDescription>
            Your data activity and portfolio value
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Portfolio Stats Grid */}
            <div className="grid grid-cols-2 gap-4">
              {/* Portfolio Value Section */}
              <div className="rounded-lg border bg-card p-4">
                <div className="space-y-2">
                  <h4 className="font-semibold">Portfolio Value</h4>
                  <p className="text-sm text-muted-foreground">
                    Based on total purchases
                  </p>
                  <Badge variant="secondary" className="text-base">
                    ${((profileStats?.total_items || 0) * 0.02).toFixed(2)}
                  </Badge>
                </div>
              </div>

              {/* Available Credits Section */}
              <div className="rounded-lg border bg-card p-4">
                <div className="space-y-2">
                  <h4 className="font-semibold">Available Credits</h4>
                  <p className="text-sm text-muted-foreground">
                    Current balance
                  </p>
                  <Badge variant="secondary" className="text-base">
                    {profileStats?.credits || 0} credits
                  </Badge>
                </div>
              </div>
            </div>

            {/* Activity List */}
            <div className="space-y-4">
              <h4 className="font-semibold">Activity Overview</h4>

              <div className="rounded-lg border divide-y">
                {/* Purchases Activity */}
                <div className="p-4 flex items-center justify-between">
                  <div>
                    <p className="font-medium">Purchases</p>
                    <p className="text-sm text-muted-foreground">
                      Total items purchased
                    </p>
                  </div>
                  <Badge variant="outline" className="ml-auto">
                    <CountUp end={portfolioData.totalPurchases} duration={2} />
                  </Badge>
                </div>

                {/* Browser Activity */}
                <div className="p-4 flex items-center justify-between">
                  <div>
                    <p className="font-medium">Browser Activity</p>
                    <p className="text-sm text-muted-foreground">
                      Browsing data statistics
                    </p>
                  </div>
                  <Badge variant="outline" className="ml-auto">
                    {portfolioData.browserActivity}
                  </Badge>
                </div>

                {/* Social Media Activity */}
                <div className="p-4 flex items-center justify-between">
                  <div>
                    <p className="font-medium">Social Media</p>
                    <p className="text-sm text-muted-foreground">
                      Social engagement data
                    </p>
                  </div>
                  <Badge variant="outline" className="ml-auto">
                    {portfolioData.socialMediaActivity}
                  </Badge>
                </div>

                {/* App Activity */}
                <div className="p-4 flex items-center justify-between">
                  <div>
                    <p className="font-medium">App Usage</p>
                    <p className="text-sm text-muted-foreground">
                      Application usage data
                    </p>
                  </div>
                  <Badge variant="outline" className="ml-auto">
                    {portfolioData.appActivity}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const DataAssetsContent: React.FC = () => (
    <div className="grid gap-4 md:gap-6">
      <StatsSection />
      <PortfolioCard />
    </div>
  );

  return (
    <PageWrapper>
      <div className="container mx-auto px-4 text-center max-w-7xl">
        <h1 className="text-3xl md:text-4xl font-extrabold tracking-tight mb-2">
          Balance
        </h1>
        <div>
          <div className="max-w-2xl mx-auto">
            <Tabs defaultValue="wallet" className="w-full">
              <TabsList className="w-full grid grid-cols-2 bg-muted/50 rounded-lg p-1">
                <TabsTrigger
                  value="wallet"
                  className="rounded-md text-sm ring-offset-background transition-all hover:bg-background hover:text-foreground data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
                >
                  Wallet
                </TabsTrigger>
                <TabsTrigger
                  value="assets"
                  className="rounded-md text-sm ring-offset-background transition-all hover:bg-background hover:text-foreground data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
                >
                  Data Assets
                </TabsTrigger>
              </TabsList>
              <div className="mt-6 md:mt-8">
                <TabsContent value="wallet" className="space-y-4 md:space-y-6">
                  <WalletContent />
                </TabsContent>
                <TabsContent value="assets" className="space-y-4 md:space-y-6">
                  <DataAssetsContent />
                </TabsContent>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Wallet;
