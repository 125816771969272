import { _getHeaders, URL_BASE } from "./shared";

export async function getPoints() {
  return fetch(`${URL_BASE}/points`, {
    method: "GET",
    headers: await _getHeaders(),
  });
}

export async function getLeaderboard() {
  const response = await fetch(`${URL_BASE}/leaderboard`, {
    method: "GET",
    headers: await _getHeaders(),
  });

  if (response.ok) {
    return response.json();
  }

  throw new Error("Failed to fetch leaderboard data");
}
