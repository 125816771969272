import { atom } from "recoil";

interface MintingState {
  [receiptId: string]: {
    isLoading: boolean;
    error: string | null;
  };
}

export const mintingState = atom<MintingState>({
  key: "mintingState",
  default: {},
});

const getInitialEvmAddressState = () => {
  const savedState = localStorage.getItem("evmAddressState");
  return savedState
    ? JSON.parse(savedState)
    : { evmAddress: null, profileId: null };
};

export const evmAddressState = atom({
  key: "evmAddressState",
  default: getInitialEvmAddressState(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue.evmAddress === null) {
          localStorage.removeItem("evmAddressState");
        } else {
          localStorage.setItem("evmAddressState", JSON.stringify(newValue));
        }
      });
    },
  ],
});
