import { useNavigate } from "react-router-dom";
import { Users } from "lucide-react";
import { useChatsQuery } from "@/queries/agent";
import { Skeleton } from "@/components/ui/skeleton";
import GroupAgentSettingsPage from "@/pages/agents/GroupAgentSettingsPage";

function GroupSkeleton() {
  return (
    <div className="flex flex-col p-4 md:p-6 rounded-lg bg-secondary dark:bg-secondary/50 space-y-2">
      <div className="flex justify-between items-center">
        <Skeleton className="h-6 w-[200px]" />
        <Skeleton className="h-4 w-[60px]" />
      </div>
      <Skeleton className="h-4 w-[100px]" />
    </div>
  );
}

export function GroupsTab() {
  const navigate = useNavigate();
  const { data: chats, isLoading } = useChatsQuery();

  if (isLoading) {
    return (
      <div className="space-y-4 md:space-y-6">
        <ul className="space-y-3 md:space-y-4">
          {[1, 2, 3, 4, 5].map((i) => (
            <li key={i}>
              <GroupSkeleton />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="space-y-4 md:space-y-6">
      <ul className="space-y-3 md:space-y-4">
        {chats?.map((chat) => (
          <li
            key={chat.id}
            className="flex flex-col p-4 md:p-6 rounded-lg cursor-pointer bg-secondary dark:bg-secondary/50 hover:bg-secondary/80 dark:hover:bg-secondary/60 transition-colors"
            onClick={() => navigate(`/agents/group/${chat.id}`)}
          >
            <div className="flex flex-row justify-between items-center">
              <h3 className="font-bold text-sm md:text-base">{chat.title}</h3>
              <div className="text-xs md:text-sm text-gray-500 text-right flex items-center">
                <Users className="mr-1" size={14} />
                {chat.members_count}
              </div>
            </div>
            <div className="mt-2 md:mt-3">
              <p className="text-primary text-xs md:text-sm">Credits: {chat.credits}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
