// atoms/receipt.ts
import { atom } from "recoil";
import { Receipt } from "@/types/entities";
import { getAPI } from "@/api";
import {
  getReceipts,
  getReceipt,
  createReceipt as createReceiptSupabase,
  updateReceipt as updateReceiptSupabase,
  deleteReceipt as deleteReceiptSupabase,
} from "@/api/supabase/receipts";
import { isTMA } from "@telegram-apps/sdk-react";

// State atom for a single Receipt
export const receiptState = atom<Receipt | null>({
  key: "singleReceiptState",
  default: null,
});

// State atom for a list of Receipts
export const receiptsState = atom<Receipt[]>({
  key: "allReceiptsState",
  default: [],
});

const API = getAPI();

/**
 * Fetch a receipt based on a receipt key.
 * @param {string} receiptKey - The key of the receipt.
 * @return {Promise<Receipt | null>} The receipt or null if not found.
 */
export async function fetchReceipt(
  receiptKey: string
): Promise<Receipt | null> {
  try {
    const isTelegram = await isTMA();
    if (isTelegram) {
      return await getReceipt(receiptKey);
    } else {
      return await API.receipt.fetchOne(receiptKey);
    }
  } catch (error) {
    console.error("Failed to fetch receipt:", error);
    return null;
  }
}

/**
 * Create a new receipt and update the Recoil state.
 * @param {Receipt} data - The receipt data to set.
 * @param {Function} setReceipts - Function to update the receipt state.
 * @param {string} [receiptKey] - Optional transaction key to set.
 * @return {Promise<Receipt>} The receipt data that was set.
 */
export async function createReceipt(
  data: Receipt,
  setReceipts: (callback: (receipts: Receipt[]) => Receipt[]) => void,
  receiptKey?: string
): Promise<Receipt> {
  try {
    const isTelegram = await isTMA();
    const receipt = await (isTelegram
      ? createReceiptSupabase(data, receiptKey)
      : API.receipt.create(data, receiptKey));

    setReceipts((prevReceipts: Receipt[]) => [...prevReceipts, receipt]);

    return receipt;
  } catch (error) {
    console.error("Failed to create receipt:", error);
    throw error;
  }
}

/**
 * Update an existing receipt and update the Recoil state.
 * @param {Receipt} data - The receipt data to update.
 * @param {string} receiptKey - The key of the receipt.
 * @param {Function} setReceipts - Function to update the receipts state.
 * @return {Promise<Receipt>} The updated receipt data.
 */
export async function updateReceipt(
  data: Receipt,
  receiptKey: string,
  setReceipts: (callback: (receipt: Receipt[]) => Receipt[]) => void
): Promise<Receipt> {
  try {
    const isTelegram = await isTMA();
    const receipt = await (isTelegram
      ? updateReceiptSupabase(receiptKey, data)
      : API.receipt.update(receiptKey, data));

    setReceipts((prevReceipts: Receipt[]) =>
      prevReceipts.map((receipt) =>
        receipt.id === receiptKey ? { ...data, id: receiptKey } : receipt
      )
    );

    return receipt;
  } catch (error) {
    console.error("Failed to update receipt:", error);
    throw error;
  }
}

/**
 * Fetch all receipts.
 * @return {Promise<Receipt[]>} List of all receipts.
 */
export async function fetchAllReceipts(): Promise<Receipt[]> {
  try {
    let { receipts: dataResponse, receiptImages: imagesResponse } =
      await API.receipt.fetchAll();

    const isTelegram = await isTMA();
    if (isTelegram) {
      dataResponse = await getReceipts();
    }

    const imagesMap = new Map(
      imagesResponse.map((image) => [
        image.name.replace(/\.\w+$/, ""),
        image.downloadUrl,
      ])
    );

    const receipts = dataResponse.map((receipt) => {
      const imageUrl = imagesMap.get(receipt.id || "");
      return {
        ...receipt,
        imageUrl,
      };
    });

    return receipts;
  } catch (error) {
    console.error("Error loading receipts:", error);
    return [];
  }
}

/**
 * Delete a receipts and update the Recoil state.
 * @param {Receipt} data - The receipt data to update.
 * @param {string} receiptKey - The key of the receipt.
 * @param {string} imagePath - The imageUrl of the receipt.
 * @param {Function} setReceipts - Function to update the receipts state.
 * @return {Promise<{ success: boolean; message: string }>} The result of the deletion operation.
 */
export async function deleteReceipt(
  data: Receipt,
  receiptKey: string,
  imagePath: string,
  setReceipts: (callback: (receipts: Receipt[]) => Receipt[]) => void
): Promise<{ success: boolean; message: string }> {
  try {
    const isTelegram = await isTMA();
    const response = await (isTelegram
      ? deleteReceiptSupabase(receiptKey)
      : API.receipt.delete(receiptKey, imagePath, data));
    if (response.success) {
      setReceipts((prevReceipts: Receipt[]) =>
        prevReceipts.filter((receipt) => receipt.id !== receiptKey)
      );
    }
    return response;
  } catch (error) {
    console.error("Failed to delete receipt:", error);
    return {
      success: false,
      message: `Failed to delete receipt with key ${receiptKey}.`,
    };
  }
}
