type PageWrapperProps = {
  children: React.ReactNode;
};

export default function PageWrapper({ children }: PageWrapperProps) {
  return (
    <div className="container mx-auto px-4 md:px-6 max-w-2xl min-h-screen pt-14 md:pt-20 pb-16 md:pb-24">
      {children}
    </div>
  );
}
