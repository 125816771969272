// utils/hapticFeedback.ts
import { postEvent } from "@telegram-apps/sdk-react";
import { isTMA } from "@telegram-apps/sdk-react";

export const triggerHapticFeedback = async () => {
  const isTma = await isTMA();
  if (isTma) {
    postEvent("web_app_trigger_haptic_feedback", {
      type: "impact",
      impact_style: "medium",
    });
  } else {
    console.warn(
      "Haptic feedback is not supported outside of the Telegram Mini App."
    );
  }
};
