import { atom } from "recoil";
import { Profile } from "@/types/entities";
import { getAPI } from "@/api";
import { isTMA } from "@telegram-apps/sdk-react";
import {
  getProfile,
  // getProfiles,
  createProfile as createProfileSupabase,
  updateProfile as updateProfileSupabase,
  deleteProfile as deleteProfileSupabase,
} from "@/api/supabase/profiles";

// Constants
const PROFILE_KEY = "profile";

// State atom for a profile
export const profileState = atom<Profile | null>({
  key: PROFILE_KEY,
  default: null,
});

const API = getAPI();

/**
 * Fetch a profile based on a user key.
 * @param {string} userKey - The key of the user.
 * @return {Promise<Profile | null>} The user's profile or null if not found.
 */
export async function fetchProfile(userKey: string): Promise<Profile | null> {
  try {
    const isTelegram = await isTMA();
    return isTelegram
      ? await getProfile()
      : await API.profile.fetchProfile(userKey);
  } catch (error) {
    console.error("Failed to fetch profile:", error);
    return null;
  }
}

/**
 * Create or update a profile.
 * @param {string} authKey - The authentication key of the user.
 * @param {Profile} data - The profile data to set.
 * @return {Profile} The profile data that was set.
 */
export async function createOrUpdateProfile(
  authKey: string,
  data: Partial<Profile>
): Promise<Profile> {
  if (!data.username) {
    throw new Error("Username is required");
  }

  try {
    const isTelegram = await isTMA();
    if (isTelegram) {
      return data.id
        ? await updateProfileSupabase(data as Profile)
        : await createProfileSupabase(data as Profile);
    } else {
      return await API.profile.createOrUpdateProfile(authKey, data);
    }
  } catch (error) {
    console.error("Failed to create or update profile:", error);
    throw error;
  }
}

/**
 * Delete a profile based on a user key.
 * @param {string} userKey - The key of the user.
 * @return {Promise<{ success: boolean; message: string }>} The result of the deletion operation.
 */
export async function deleteProfile(
  userKey: string
): Promise<{ success: boolean; message: string }> {
  try {
    const isTelegram = await isTMA();
    return isTelegram
      ? await deleteProfileSupabase(userKey)
      : await API.profile.deleteProfile(userKey);
  } catch (error) {
    console.error("Failed to delete profile:", error);
    throw error;
  }
}
