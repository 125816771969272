"use client";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import iconUrl from "../../assets/ICONWhite.png";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";
import { cn } from "@/lib/utils";
import { MainMenuToggle } from "./MainMenuToggle";
import { ThemeToggle } from "./ThemeToggle";
import { Input } from "@/components/ui/input";

export function SiteHeader() {
  const location = useLocation();

  const routes = [
    { href: "/", label: "Overview" },
    { href: "/agents", label: "Agents" },
    { href: "/leaderboard", label: "Leaderboard" },
    { href: "/wallet", label: "Wallet" },
  ];

  return (
    <header className="fixed top-0 z-50 w-full bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-b">
      {/* Desktop Only Content */}
      <div className="hidden md:flex container h-14 max-w-screen-xl items-center justify-between px-2 lg:px-8">
        <Link to="/" className="flex items-center">
          <img
            src={iconUrl}
            alt="Bridge23"
            className="h-[45px] w-[45px] dark:brightness-100 brightness-0"
          />
        </Link>
        
        <div className="flex flex-1 items-center space-x-4 lg:space-x-6 ml-6">
          {routes.map((route) => (
            <Link
              key={route.href}
              to={route.href}
              className={cn(
                "text-sm font-medium transition-colors hover:text-primary",
                location.pathname === route.href
                  ? "text-primary"
                  : "text-muted-foreground"
              )}
            >
              {route.label}
            </Link>
          ))}
        </div>

        <div className="flex items-center space-x-4">
          <div className="relative w-60">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search..."
              className="pl-8 w-full"
            />
          </div>
          <ThemeToggle />
          <MainMenuToggle />
        </div>
      </div>

      {/* Empty Mobile Header */}
      <div className="h-14 md:hidden"></div>
    </header>
  );
}
