import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Ed25519KeyIdentity } from "@dfinity/identity";
import { TonConnectButton } from "@tonconnect/ui-react";
import { SendICPForm } from "./SendICPForm";
import { ReceiveICPForm } from "./ReceiveICPForm";
import { SendBUDForm } from "./SendBUDForm";
import { ReceiveBUDForm } from "./ReceiveBUDForm";
import { QueryClient } from "@tanstack/react-query";

interface TokenBalanceCardProps {
  type: 'TON' | 'BUD' | 'ICP';
  balance: string;
  isLoading: boolean;
  principalId?: string;
  identity?: Ed25519KeyIdentity;
  onTransferComplete?: () => void;
  showTonConnect?: boolean;
}

interface TokenConfig {
  name: string;
  fullName?: string;
  logo: string;
}

const TOKEN_CONFIG: Record<string, TokenConfig> = {
  TON: {
    name: 'TON',
    fullName: 'TON',
    logo: '/tonlogo.png'
  },
  BUD: {
    name: 'BUD',
    fullName: 'Bridge Unit of Data',
    logo: '/bud.png'
  },
  ICP: {
    name: 'ICP',
    fullName: 'Internet Computer',
    logo: '/icp.png'
  }
};

export const TokenBalanceCard: React.FC<TokenBalanceCardProps> = ({
  type,
  balance,
  isLoading,
  principalId,
  identity,
  onTransferComplete,
  showTonConnect = false
}) => {
  const config = TOKEN_CONFIG[type];

  const renderActions = () => {
    if (type === 'TON') {
      return showTonConnect && <TonConnectButton />;
    }

    return (
      <div className="flex space-x-2">
        <div className="flex items-center">
          {type === 'ICP' ? (
            <ReceiveICPForm principalId={principalId || ""} />
          ) : (
            <ReceiveBUDForm principalId={principalId || ""} />
          )}
          <span className="ml-1 text-sm text-muted-foreground">Receive</span>
        </div>
        <div className="flex items-center">
          {type === 'ICP' && identity ? (
            <SendICPForm
              identity={identity}
              onTransferComplete={onTransferComplete}
            />
          ) : type === 'BUD' && identity ? (
            <SendBUDForm
              identity={identity}
              onTransferComplete={onTransferComplete}
            />
          ) : null}
          <span className="ml-1 text-sm text-muted-foreground">Send</span>
        </div>
      </div>
    );
  };

  return (
    <Card className="bg-card">
      <CardHeader className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <img src={config.logo} alt={`${config.name} Logo`} className="h-8 w-8 mr-2" />
          <CardTitle className="text-xl font-bold text-card-foreground">
            {config.fullName || config.name}
          </CardTitle>
        </div>
        {renderActions()}
      </CardHeader>
      <CardContent className="text-center">
        <div className="flex justify-between">
          <p className="text-muted-foreground">Balance</p>
          <p className="text-lg font-bold flex text-card-foreground">
            {isLoading ? "Loading..." : `${balance} ${config.name}`}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default TokenBalanceCard; 