import { _getHeaders, URL_BASE } from "./shared";
import { AgentSettings, Chat, ChatSummary } from "@/types/entities";

export async function getAgentSettings(): Promise<AgentSettings> {
  const url = `${URL_BASE}/agent-settings/me`;
  console.log("Fetching agent settings from:", url);

  const response = await fetch(url, {
    method: "GET",
    headers: await _getHeaders(),
  });

  if (!response.ok) {
    console.error(
      "Failed to fetch agent settings:",
      response.status,
      response.statusText
    );
    throw new Error(`Failed to fetch agent settings: ${response.statusText}`);
  }

  return response.json();
}

export async function updateAgentSettings(
  settings: Partial<Pick<AgentSettings, "agent_settings">>
) {
  const url = `${URL_BASE}/agent-settings/me`;
  console.log("Updating agent settings at:", url, settings);

  const response = await fetch(url, {
    method: "PUT",
    headers: await _getHeaders(),
    body: JSON.stringify(settings),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error(
      "Failed to update agent settings:",
      response.status,
      errorText
    );
    throw new Error(`Failed to update agent settings: ${errorText}`);
  }

  return response.json();
}

export async function getGroupSettings(chatId: string): Promise<AgentSettings> {
  const url = `${URL_BASE}/agent-settings/${chatId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: await _getHeaders(),
  });

  const textData = await response.text();
  // console.log("Settings data:", textData);

  return JSON.parse(textData);
}

export async function updateGroupSettings(
  chatId: string,
  settings: Partial<AgentSettings>
) {
  const url = `${URL_BASE}/agent-settings/${chatId}`;
  console.log("Updating group settings at:", url, settings);

  const requestPayload = {
    agent_settings: settings.agent_settings,
    chat_settings: settings.chat_settings || {},
    stats: settings.stats || {},
  };

  const response = await fetch(url, {
    method: "PUT",
    headers: await _getHeaders(),
    body: JSON.stringify(requestPayload),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error(
      "Failed to update group settings:",
      response.status,
      errorText
    );
    throw new Error(`Failed to update group settings: ${errorText}`);
  }

  return response.json();
}

export async function getChats(): Promise<Chat[]> {
  const url = `${URL_BASE}/chats`;

  const response = await fetch(url, {
    method: "GET",
    headers: await _getHeaders(),
  });

  const textData = await response.text();

  const jsonData = JSON.parse(textData);

  return jsonData;
}

export async function getChatSummaries(): Promise<ChatSummary[]> {
  const url = `${URL_BASE}/chat-summaries`;
  // console.log("Fetching chat summaries from:", url);

  const response = await fetch(url, {
    method: "GET",
    headers: await _getHeaders(),
  });
  // console.log("Chat summaries response:", response);

  if (!response.ok) {
    console.error(
      "Failed to fetch chat summaries:",
      response.status,
      response.statusText
    );
    throw new Error(`Failed to fetch chat summaries: ${response.statusText}`);
  }

  return response.json();
}

export async function getChatSummaryById(id: string): Promise<ChatSummary> {
  const url = `${URL_BASE}/chat-summaries/${id}`;
  // console.log("Fetching chat summary from:", url);

  const response = await fetch(url, {
    method: "GET",
    headers: await _getHeaders(),
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch chat summary: ${response.statusText}`);
  }

  return response.json();
}

export async function updateChatSummary(
  summaryId: string,
  data: { data_protector: string }
): Promise<void> {
  const url = `${URL_BASE}/chat-summaries/${summaryId}`;
  console.log("Updating chat summary:", { url, data });

  const response = await fetch(url, {
    method: "PATCH",
    headers: await _getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error("Update chat summary error:", {
      status: response.status,
      statusText: response.statusText,
      errorText,
    });
    throw new Error(`Failed to update chat summary: ${errorText}`);
  }

  const result = await response.json();
  console.log("Update chat summary result:", result);
  return result;
}
