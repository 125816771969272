import React, { useEffect, useState } from "react";
import { getLeaderboard } from "@/api/supabase/rewards-system";
import { handleShareTelegramReferralLink } from "@/api/telegram/referral-link";
import PageWrapper from "@/components/layout/PageWrapper";
import { Button } from "@/components/ui/button";
import { UsersThree } from "@phosphor-icons/react";
import { useIsTMA } from "@/hooks/useIsTMA";
import { LeaderboardEntry, LeaderboardData } from "@/types/entities";
import { Profile } from "@/types/entities";
import { getManyDocs } from "@junobuild/core";
import { useQuery } from "@tanstack/react-query";

export default function Leaderboard() {
  const { isTma } = useIsTMA();

  const {
    isPending: loading,
    error,
    data: leaderboardData,
  } = useQuery({ queryKey: ["leaderboard"], queryFn: getLeaderboard });

  const {
    isPending: loadingProfiles,
    error: errorProfiles,
    data: userProfiles,
  } = useQuery({
    queryKey: ["leaderboard", leaderboardData ? "profiles" : null],
    queryFn: async () => {
      const docPairs = leaderboardData.top_100.map(
        (entry: LeaderboardEntry) => ({
          collection: "profiles",
          key: entry.principal_id,
        })
      );
      const profiles = await getManyDocs({ docs: docPairs });
      const profilesMap: Record<string, Profile> = {};
      profiles.forEach((profile) => {
        if (profile && profile.data) {
          profilesMap[profile.key] = profile.data as Profile;
        }
      });

      return profilesMap;
    },
    enabled: !!leaderboardData,
  });

  function getMedalColor(rank: number) {
    switch (rank) {
      case 1:
        return "text-yellow-400 dark:text-yellow-500";
      case 2:
        return "text-zinc-400 dark:text-zinc-300";
      case 3:
        return "text-amber-700 dark:text-amber-600";
      default:
        return "text-muted-foreground";
    }
  }

  function getMedalIcon(rank: number) {
    switch (rank) {
      case 1:
        return "🥇";
      case 2:
        return "🥈";
      case 3:
        return "🥉";
      default:
        return null;
    }
  }

  const userRank = leaderboardData?.my_rank || null;
  const renderLeaderboardItem = (entry: LeaderboardEntry, index: number) => {
    if (!userProfiles) {
      return null;
    }

    return (
      <li
        key={entry.id}
        className={`flex justify-between items-center rounded-lg p-4 w-full ${
          entry.username === leaderboardData?.username
            ? "bg-primary/10 dark:bg-primary/20"
            : "bg-secondary dark:bg-secondary/50"
        }`}
      >
        <div className="flex items-center space-x-4 w-full">
          <div className="flex items-center">
            {userProfiles[entry.principal_id] ? (
              <div
                className="w-8 h-8 rounded-md overflow-hidden"
                dangerouslySetInnerHTML={{
                  __html: userProfiles[entry.principal_id].avatar_svg,
                }}
                style={{
                  backgroundColor: userProfiles[entry.principal_id].color,
                }}
              />
            ) : (
              <div className="bg-muted rounded-full w-8 h-8 flex items-center justify-center">
                <span className="text-muted-foreground">
                  {entry.username.slice(0, 2).toUpperCase()}
                </span>
              </div>
            )}
            <div className="ml-2 flex flex-col justify-center">
              <span className="text-md font-medium text-foreground">
                {entry.username}
              </span>
              <span className="text-sm text-muted-foreground mt-0.5">
                {`${entry.total_points} BRD23`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center text-lg font-bold ml-auto">
          {index + 1 <= 3 ? (
            <span className={`text-3xl ${getMedalColor(index + 1)}`}>
              {getMedalIcon(index + 1)}
            </span>
          ) : (
            <span className="text-muted-foreground">#{index + 1}</span>
          )}
        </div>
      </li>
    );
  };

  return (
    <PageWrapper>
      <div className="flex flex-col items-center justify-center mt-4 w-full max-w-2xl mx-auto">
        <div className="text-center">
          <h3 className="text-6xl font-bold text-foreground">
            #{userRank || "N/A"}🏆
          </h3>
          <p className="text-lg text-muted-foreground mt-2">
            Your Leaderboard Rank
          </p>
        </div>

        {/* Main Quest */}
        <div className="bg-gradient-to-br from-blue-500/40 to-purple-500/40 dark:from-blue-500/20 dark:to-purple-500/20 px-4 py-6 rounded-lg shadow-lg border w-full mt-4 mb-8">
          <div>
            <h4 className="text-xl font-bold text-card-foreground">
              Boost Your Airdrop Allocation!
            </h4>
          </div>
          {isTma && (
            <Button
              className="bg-primary mt-4 w-full text-xl py-6"
              size={"lg"}
              onClick={() => handleShareTelegramReferralLink()}
            >
              <UsersThree size={24} className="mr-2" />
              Invite Friends
            </Button>
          )}
          <h5 className="mt-2 text-muted-foreground">
            Everybody who joins Bridge23 with your link will bring you 1,000
            Devotion Points + 20% of their points.
          </h5>
        </div>

        {/* Leaderboard List */}
        <h2 className="font-bold text-xl text-foreground mb-4">Leaderboard</h2>
        <div className="bg-background mt-2 w-full mx-auto rounded-lg">
          {loading ? (
            <p className="text-muted-foreground">Loading leaderboard data...</p>
          ) : error ? (
            <p className="text-destructive">{error.toString()}</p>
          ) : leaderboardData ? (
            <>
              <ul className="space-y-4 w-full">
                {leaderboardData.top_100.map(renderLeaderboardItem)}
              </ul>
            </>
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
}
