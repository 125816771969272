import { getAccessToken } from "@privy-io/react-auth";
import { retrieveLaunchParams, isTMA } from "@telegram-apps/sdk-react";

export const URL_BASE = import.meta.env.VITE_API_URL_BASE;

export async function _getHeaders() {
  const isTma = await isTMA();
  if (!isTma) {
    const privyToken = await getAccessToken();
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Privy ${privyToken}`,
      "X-Bridge23-Env": import.meta.env.PROD ? "production" : "local",
    };
  }

  const { initDataRaw } = retrieveLaunchParams();

  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `TMA ${btoa(initDataRaw!)}`,
    "X-Bridge23-Env": import.meta.env.PROD ? "production" : "local",
  };
}
