import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { _getHeaders, URL_BASE } from "@/api/supabase/shared";
import WebApp from "@twa-dev/sdk";

const CreditPurchaseOptions = [
  { credits: 10, stars: 10 },
  { credits: 20, stars: 20 },
  { credits: 50, stars: 50 },
  { credits: 100, stars: 100 },
  { credits: 200, stars: 200 },
  { credits: 500, stars: 500 },
  { credits: 1000, stars: 1000 },
];

interface CreditPurchaseDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title?: string;
  description?: string;
  onSuccess?: () => void;
}

export function CreditPurchaseDialog({
  open,
  onOpenChange,
  title = "Buy Credits with Stars",
  description = "1 Star = 1 Credit",
  onSuccess,
}: CreditPurchaseDialogProps) {
  const { toast } = useToast();

  const handlePurchaseCredits = async (amount: number) => {
    try {
      const response = await fetch(`${URL_BASE}/create-invoice`, {
        method: "POST",
        headers: await _getHeaders(),
        body: JSON.stringify({ amount }),
      });

      if (!response.ok) {
        toast({
          variant: "destructive",
          title: "Payment Failed",
          description: "Could not create payment invoice. Please try again.",
        });
        return;
      }

      const { invoiceLink } = await response.json();

      WebApp.openInvoice(invoiceLink, async (status) => {
        if (status === "paid") {
          const updateResponse = await fetch(`${URL_BASE}/payment-webhook`, {
            method: "POST",
            headers: await _getHeaders(),
            body: JSON.stringify({
              successful_payment: {
                invoice_payload: JSON.stringify({
                  amount: amount,
                }),
              },
            }),
          });

          if (updateResponse.ok) {
            toast({
              title: "Purchase Successful",
              description: `${amount} credits have been added to your account!`,
              className: "bg-green-500",
            });
            onOpenChange(false);
            onSuccess?.();
          }
        } else {
          toast({
            variant: "destructive",
            title: "Payment Cancelled",
            description: "The payment process was cancelled.",
          });
        }
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
      });
      console.error("Error purchasing credits:", error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center text-xl font-bold">
            {title}
          </DialogTitle>
          <DialogDescription className="text-center text-gray-500">
            {description}
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-1 gap-3 p-4">
          {CreditPurchaseOptions.map((option) => (
            <Button
              key={option.credits}
              onClick={() => handlePurchaseCredits(option.stars)}
              variant="outline"
              className="flex justify-between items-center p-6 hover:bg-blue-50 dark:hover:bg-blue-900/20 transition-colors"
            >
              <div className="flex items-center gap-3">
                <span className="text-lg font-semibold">
                  {option.credits} Credits
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-yellow-500">★</span>
                <span className="font-medium">{option.stars}</span>
              </div>
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
