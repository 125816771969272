import { useState, useEffect } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Slider } from "@/components/ui/slider";
import { Textarea } from "@/components/ui/textarea";
import { AgentGoal } from "@/utils/constants";
import { AgentSettings } from "@/types/entities";

interface AgentSettingsCardProps {
  settings: AgentSettings["agent_settings"];
  isLoading: boolean;
  updateSettings: (settings: Partial<AgentSettings["agent_settings"]>) => void;
  isPending: boolean;
}

export function AgentSettingsCard({ settings, isLoading, updateSettings, isPending }: AgentSettingsCardProps) {
  const [mainGoal, setMainGoal] = useState(settings.mainGoal || "general");
  const [temperature, setTemperature] = useState(settings.temperature || 0.5);
  const [language, setLanguage] = useState(settings.language || "English");
  const [customPrompt, setCustomPrompt] = useState(settings.customGoalPrompt || "");

  useEffect(() => {
    setMainGoal(settings.mainGoal || "general");
    setTemperature(settings.temperature || 0.5);
    setLanguage(settings.language || "English");
    setCustomPrompt(settings.customGoalPrompt || "");
  }, [settings]);

  const handleSave = async () => {
    updateSettings({
      language,
      mainGoal,
      customGoalPrompt: mainGoal === "custom" ? customPrompt : undefined,
      temperature,
      knowledgeBase: settings.knowledgeBase,
    });
  };

  if (isLoading) {
    return (
      <Card className="w-full">
        <CardContent className="text-center p-4 md:p-6">
          <div className="h-10 w-full bg-secondary animate-pulse rounded" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader className="p-4 md:p-6">
        <CardTitle className="text-lg md:text-xl">Agent Settings</CardTitle>
      </CardHeader>
      <CardContent className="p-4 md:p-6 space-y-4 md:space-y-6">
        <div className="grid gap-4 md:gap-6">
          <div className="space-y-2">
            <label className="text-sm md:text-base font-medium">Language:</label>
            <Select value={language} onValueChange={setLanguage}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="English" />
              </SelectTrigger>
              <SelectContent className="max-h-[200px] overflow-y-auto">
                {languages.map((lang) => (
                  <SelectItem key={lang} value={lang.toLowerCase()}>
                    {lang}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <label className="text-sm md:text-base font-medium">Main goal:</label>
            <Select value={mainGoal} onValueChange={setMainGoal}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select main goal" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={AgentGoal.GeneralChat}>General Chat</SelectItem>
                <SelectItem value={AgentGoal.Finance}>Finance</SelectItem>
                <SelectItem value={AgentGoal.NutritionAndFitness}>Nutrition and Fitness</SelectItem>
                <SelectItem value={AgentGoal.Custom}>Custom</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {mainGoal === AgentGoal.Custom && (
            <div className="space-y-2">
              <label className="text-sm md:text-base font-medium">Custom Prompt:</label>
              <Textarea
                placeholder="Enter custom prompt"
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                className="min-h-[100px] md:min-h-[120px]"
              />
            </div>
          )}

          <div className="space-y-2">
            <label className="text-sm md:text-base font-medium">
              Temperature: {temperature.toFixed(1)}
            </label>
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={[temperature]}
              onValueChange={([value]) => setTemperature(value)}
              className="py-4"
            />
            <p className="text-xs md:text-sm text-muted-foreground">
              Controls how creative vs focused the AI responses will be. Higher
              values (closer to 1.0) result in more varied and creative responses,
              while lower values (closer to 0.0) make responses more focused and
              deterministic.
            </p>
          </div>

          <div className="space-y-2">
            <label className="text-sm md:text-base font-medium">Knowledge base:</label>
            <p className="text-xs md:text-sm text-muted-foreground">Coming Soon</p>
          </div>
          <div className="pt-4 md:pt-6">
          <Button 
            onClick={handleSave} 
            disabled={isPending}
            className="w-full md:w-auto float-right"
          >
            {isPending ? "Saving..." : "Save Changes"}
          </Button>
        </div>
        </div>
      </CardContent>
    </Card>
  );
}

const languages = [
  "Afrikaans",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Belarusian",
  "Bosnian",
  "Bulgarian",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Finnish",
  "French",
  "Galician",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Marathi",
  "Maori",
  "Nepali",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Welsh",
];