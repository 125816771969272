import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { RecoilRoot } from "recoil";

import RootLayout from "./components/layout/RootLayout";
import AgentsPage from "./pages/agents/AgentsPage";
import GroupAgentSettingsPage from "./pages/agents/GroupAgentSettingsPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import WalletPage from "./pages/wallet/WalletPage";
import LoginPage from "./pages/auth/LoginPage";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import ProfilePage from "./pages/profile/ProfilePage";
// import PWABadge from "./PWABadge";
import EditTransactionPage from "./pages/transactions/EditTransactionPage.tsx";
import TimeLinePage from "./pages/timeline/StoreList";
import NewTransaction from "./pages/new_transaction/NewTransaction";
import ExchangeRate from "./pages/exchange_rate/ExchangeRate";
import ReceiptList from "./pages/timeline/ReceiptList";
import ReceiptDetailPage from "./pages/timeline/ReceiptDetailPage.tsx";
import { UnderConstruction } from "./pages/under_construction/UnderConstruction";
import { PointsPage } from "./pages/points/PointsPage.tsx";
import TasksPage from "./pages/tasks/TasksPage.tsx";
import Leaderboard from "./pages/leaderboard/LeaderboardPage.tsx";
import Introduction from "./pages/introduction/IntroPage.tsx";
import DailyStreakPage from "./pages/daily-streak/DailyStreakPage";
import { ErrorFallback } from "./components/ErrorFallback.tsx";

import * as Sentry from "@sentry/react";
import { init as initTelegram, isTMA } from "@telegram-apps/sdk-react";
import posthog from "posthog-js";
import VerifyReceipts from "./components/transactions_list/VerifyReceipts.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://80f97cb10ad8bd43806f2e38e00978dc@o4507799838916608.ingest.de.sentry.io/4507799841013840",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  posthog.init("phc_WPlpkk8zVnacXToNOj8z7qtAp7gerUvIHiSRGL4SJV5", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
}

const queryClient = new QueryClient();

isTMA().then((isTelegramApp) => {
  if (isTelegramApp) {
    initTelegram({
      version: "7.10",
      // postEvent: "non-strict",
    });
  }
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl="https://beta.bridge23.app/tonconnect-manifest.json">
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // Reset the state of app so the error doesn't blank again
              window.location.reload();
            }}
            onError={(error, info) => {
              console.error("An error occurred:", error, info);
            }}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<RootLayout />}>
                  <Route path="/" element={<TasksPage />} />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/wallet" element={<WalletPage />} />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/onboarding" element={<OnboardingPage />} />
                  <Route path="/intro" element={<Introduction />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/timeline" element={<TimeLinePage />} />
                  <Route
                    path="/daily-streak/:streakCount"
                    element={<DailyStreakPage />}
                  />
                  <Route
                    path="/transactions/:id/edit"
                    element={<EditTransactionPage />}
                  />
                  <Route path="/transaction/new" element={<NewTransaction />} />
                  <Route path="/rate" element={<ExchangeRate />} />
                  <Route path="/store/:address" Component={ReceiptList} />
                  <Route path="/receipt/:id" element={<ReceiptDetailPage />} />
                  <Route
                    path="/under_construction"
                    element={<UnderConstruction />}
                  />
                  <Route path="/points" element={<PointsPage />} />
                  <Route path="/verify-receipts" element={<VerifyReceipts />} />
                  <Route path="/agents" element={<AgentsPage />} />
                  <Route
                    path="/agents/group/:groupId"
                    element={<GroupAgentSettingsPage />}
                  />
                </Route>
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </BrowserRouter>
            {/* <PWABadge /> */}
          </ErrorBoundary>
        </RecoilRoot>
      </QueryClientProvider>
    </TonConnectUIProvider>
  </React.StrictMode>
);
