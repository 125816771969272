import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { WhatsappLogo, NotionLogo } from "@phosphor-icons/react";

const AgentsIntegrationCard = () => {
    const handleIntegrationClick = (integration: string) => {
        toast({
            title: `${integration} Integration`,
            description: "Coming soon! Stay tuned for updates.",
            duration: 3000
        });
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-xl font-bold">Integrations</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <Button 
                        variant="outline" 
                        className="flex items-center gap-2 h-12"
                        onClick={() => handleIntegrationClick('WhatsApp')}
                    >
                        <WhatsappLogo className="w-5 h-5 text-green-500" weight="fill" />
                        WhatsApp
                    </Button>
                    <Button 
                        variant="outline" 
                        className="flex items-center gap-2 h-12"
                        onClick={() => handleIntegrationClick('Notion')}
                    >
                        <NotionLogo className="w-5 h-5" weight="fill" />
                        Notion
                    </Button>
                </div>
                <p className="text-sm text-muted-foreground">
                    Connect your favorite apps to enhance your agent's capabilities.
                </p>
            </CardContent>
        </Card>
    );
};

export default AgentsIntegrationCard; 