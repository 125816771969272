import { Chat } from "@/types/entities";
import { _getHeaders, URL_BASE } from "./shared";

export async function getChatStats(chatId: string) {
  const response = await fetch(`${URL_BASE}/chat-stats/${chatId}`, {
    method: "GET",
    headers: await _getHeaders(),
  });
  console.log("getChatStats", response);

  if (!response.ok) {
    throw new Error("Failed to fetch chat statistics");
  }

  return response.json();
}

export async function getChatById(chatId: string): Promise<Chat> {
  const url = `${URL_BASE}/chats/${chatId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: await _getHeaders(),
  });

  const data = await response.text();
  // console.log("Raw chat data:", data);

  return JSON.parse(data);
}
