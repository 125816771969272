import { useEffect, useState } from "react";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { Ed25519KeyIdentity } from "@dfinity/identity";
import { User, UserData, getDoc, setDoc } from "@junobuild/core";
import { getSatteliteOptions, setAPICustomIdentity } from "@/api";
import { useRecoilState } from "recoil";
import { authState } from "@/atoms/auth";
import { documentState } from "../../atoms/document.ts";
import { useProfileQuery } from "@/queries/profiles.tsx";
import {
  isTMA,
  init,
  retrieveLaunchParams,
  postEvent,
} from "@telegram-apps/sdk-react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SAVED_IDENTITY_KEY = "siwe_internet_identity";

export function SIWEAuthProvider({ children }: { children: React.ReactNode }) {
  const { ready, authenticated, signMessage, getAccessToken } = usePrivy();
  const { wallets, ready: walletsReady } = useWallets();
  const [user, setUser] = useRecoilState<User | null>(authState);
  const navigate = useNavigate();
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);

  const { data: profile, isPending: profileLoading } =
    useProfileQuery(isInitialized);

  // TODO: this is fake sign in with ethereum, we need to use the real one by ICP
  async function signInWithEthereum() {
    const signature = await signMessage({
      message: "Sign this message to sign in to Bridge23",
    });
    console.log(signature);

    const signatureBytes = new Uint8Array(
      signature.signature
        .slice(2) // Remove '0x' prefix
        .match(/.{1,2}/g)! // Split into pairs
        .map((byte) => parseInt(byte, 16)) // Convert hex to numbers
    );

    // Ensure exactly 32 bytes by taking first 32 bytes of signature
    const bytes32 = signatureBytes.slice(0, 32);
    const identity = Ed25519KeyIdentity.generate(bytes32);
    console.log("principal:", identity.getPrincipal().toString());

    console.time("fetchUser");

    window.localStorage.setItem(
      SAVED_IDENTITY_KEY,
      JSON.stringify(identity.toJSON())
    );

    setUser({
      key: identity.getPrincipal().toText(),
      data: {
        provider: "internet_identity",
      },
    });
    setAPICustomIdentity(identity);
  }

  async function restoreIdentity() {
    const identity = Ed25519KeyIdentity.fromJSON(
      window.localStorage.getItem(SAVED_IDENTITY_KEY)!
    );
    console.log("restored identity", identity);
    setUser({
      key: identity.getPrincipal().toText(),
      data: {
        provider: "internet_identity",
      },
    });
    setAPICustomIdentity(identity);
  }

  async function tryInitTelegramMiniApp() {
    const isTma = await isTMA();

    if (!isTma) {
      return;
    }

    // @ts-ignore
    if (window?.Telegram?.WebApp) {
      // @ts-ignore
      window.Telegram.WebApp.expand();
      // @ts-ignore
      window.Telegram.WebApp.requestFullscreen();
    }

    init();
  }

  useEffect(() => {
    if (ready && authenticated && walletsReady) {
      if (window.localStorage.getItem(SAVED_IDENTITY_KEY)) {
        restoreIdentity().then(() => {
          setIsInitialized(true);
          tryInitTelegramMiniApp();
        });
      } else {
        signInWithEthereum().then(() => {
          setIsInitialized(true);
          tryInitTelegramMiniApp();
        });
      }
    }
  }, [ready, authenticated, walletsReady]);

  useEffect(() => {
    if (isInitialized && !profileLoading) {
      if (!profile?.introduction_completed) {
        console.log("onboarding not completed");
        // navigate("/onboarding");
        navigate("/intro");
      } else {
        // if (location.pathname === "/onboarding") {
        if (location.pathname === "/intro") {
          navigate("/");
        }
      }
    }
  }, [isInitialized, profileLoading]);

  if (!isInitialized) {
    return null;
  }

  return children;
}
