import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import { Zap } from "lucide-react"
import { Trophy, Star, Sparkle } from "@phosphor-icons/react"
import { fetchRewards } from "@/atoms/rewards";
import { useQuery } from "@tanstack/react-query";
import { useProfileQuery } from "@/queries/profiles";
import numeral from "numeral";

interface StatsCardProps {
  credits?: number
  streak?: number
  isLoading?: boolean
}

export function StatsCard({ credits = 0, streak = 0, isLoading = false }: StatsCardProps) {
  const { data: profile } = useProfileQuery();

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
    retry: true,
    enabled: !!profile,
  });

  if (isLoading) {
    return (
      <Card className="bg-card">
        <CardContent className="pt-6">
          <div className="space-y-4">
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className="bg-card">
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="text-xl font-bold text-card-foreground">
          Stats & Credits
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Zap className="h-4 w-4 text-yellow-500" />
              <p className="text-muted-foreground">Available Credits</p>
            </div>
            <p className="font-bold text-card-foreground">{numeral(credits).format('0,0')}</p>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Trophy className="h-4 w-4 text-orange-500" />
              <p className="text-muted-foreground">Current Streak</p>
            </div>
            <p className="font-bold text-card-foreground">{streak} days</p>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Sparkle className="h-4 w-4 text-purple-500" weight="fill" />
              <p className="text-muted-foreground">Devotion Points</p>
            </div>
            <p className="font-bold text-card-foreground">
              {numeral(rewards?.total_points || 0).format('0,0')}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
} 