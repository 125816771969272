// src/pages/TasksPage.tsx

import { formatNumber } from "@/lib/utils";
import { handleShareTelegramReferralLink } from "@/api/telegram/referral-link";
import PageWrapper from "@/components/layout/PageWrapper";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { isTMA } from "@telegram-apps/sdk-react";
import { Task } from "@/types/entities";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSpring, animated, SpringValue } from "@react-spring/web";
import { ArrowClockwise, Receipt, UsersThree } from "@phosphor-icons/react";
import { Skeleton } from "@/components/ui/skeleton";
import { getTasks } from "@/api/supabase/tasks";
import { handleCheckTask, handleStartTask } from "./taskHandlers";
import { useAnalyzeReceipt } from "@/components/upload/upload-drawer-receipts";
import Loading from "@/components/upload/UploadLoading";
import { useToast } from "@/components/ui/use-toast";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { useShareStory } from "@/hooks/useShareStory";
import { fetchRewards } from "@/atoms/rewards";
import { fetchProfileStats } from "@/atoms/profile_stats";
import { getStats } from "@/api/supabase/profiles";
import { useProfileQuery } from "@/queries/profiles";

interface MainQuestProps {
  totalUploads: number;
  progress: { width: SpringValue<string> };
  handleUploadClick: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileUpload: (file: File) => void;
  isLoading: boolean;
  isAnalyzing: boolean;
  uploadSuccess: boolean;
  error: string | null;
  handleClaimPointsClick: () => void;
  nextTier: { uploads: number; points: number } | null;
  hasReachedUploads: boolean;
}

interface TaskListProps {
  tasksLoading: boolean;
  availableTasks: Task[];
  completedTasks: Task[];
  handleStartTask: (task: Task, navigate: NavigateFunction) => void;
  checkTaskHandler: (task: Task) => void;
  loadingTasks: { [key: number]: boolean };
  navigate: NavigateFunction;
  postStory: (task: Task) => Promise<void>;
  storyBonusClaimed: boolean;
}

const tiers = [
  { uploads: 500, points: 5000, taskId: 5000 },
  { uploads: 1000, points: 12000, taskId: 12000 },
  { uploads: 2000, points: 25000, taskId: 25000 },
  { uploads: 5000, points: 70000, taskId: 70000 },
  { uploads: 10000, points: 150000, taskId: 150000 },
];

const MainQuest: React.FC<MainQuestProps> = ({
  totalUploads,
  progress,
  handleUploadClick,
  fileInputRef,
  handleFileUpload,
  isLoading,
  isAnalyzing,
  uploadSuccess,
  error,
  handleClaimPointsClick,
  nextTier,
  hasReachedUploads,
}) => {
  if (!nextTier) return null;

  return (
    <div className="bg-gradient-to-br from-blue-500/40 to-purple-500/40 dark:from-blue-500/20 dark:to-purple-500/20 px-4 py-6 rounded-lg shadow border w-full mt-4">
      <div className="flex flex-row justify-between items-center">
        <div>
          <h4 className="text-xl font-bold text-card-foreground">Main Quest</h4>
          <h5 className="mt-1 text-muted-foreground">
            You Uploaded {totalUploads}/{nextTier.uploads} products
          </h5>
        </div>
        <h5 className="text-xl font-bold text-card-foreground">
          +{nextTier.points} DP
        </h5>
      </div>
      <div className="relative w-full h-4 bg-secondary rounded-full mt-4 overflow-hidden">
        <animated.div
          style={progress}
          className="h-full bg-primary"
        ></animated.div>
      </div>
      {hasReachedUploads ? (
        <Button
          className="mt-6 w-full text-xl py-6"
          size="lg"
          onClick={handleClaimPointsClick}
        >
          <Receipt size={24} className="mr-2" />
          Claim {nextTier.points} points!
        </Button>
      ) : (
        <>
          <Button
            className="mt-6 w-full text-xl py-6"
            size="lg"
            onClick={handleUploadClick}
          >
            <Receipt size={24} className="mr-2" />
            Upload Receipt
          </Button>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                handleFileUpload(file);
              }
            }}
            className="hidden"
            ref={fileInputRef}
          />
        </>
      )}
      {(isLoading || isAnalyzing) && (
        <Loading
          statusText={
            isLoading ? "Uploading..." : isAnalyzing ? "Analyzing..." : ""
          }
        />
      )}
      {uploadSuccess && (
        <p className="flex text-green-500 justify-center text-center">
          Upload successful!
        </p>
      )}
      {error && (
        <div className="flex mt-8 mx-8 p-4 text-destructive-foreground justify-center text-center border rounded-md border-destructive bg-destructive/10">
          {error}
        </div>
      )}
    </div>
  );
};

const TaskList: React.FC<TaskListProps> = ({
  tasksLoading,
  availableTasks,
  completedTasks,
  handleStartTask,
  checkTaskHandler,
  loadingTasks,
  navigate,
  postStory,
  storyBonusClaimed,
}) => (
  <TabsContent value="tasks">
    <Card className="mb-4 md:mb-6">
      <CardContent className="p-4 md:p-6">
        <p className="text-sm md:text-base text-muted-foreground">
          Complete tasks and earn Devotion Points 🙏 The most devoted beta
          testers will be airdropped Bridge23 tokens after the beta ends.
        </p>
      </CardContent>
    </Card>

    {tasksLoading ? (
      <>
        <Skeleton className="h-12 w-full mb-3 md:mb-4" />
        <Skeleton className="h-12 w-full mb-3 md:mb-4" />
        <Skeleton className="h-12 w-full mb-3 md:mb-4" />
      </>
    ) : (
      <>
        {availableTasks.length > 0 && (
          <>
            <h2 className="text-lg md:text-xl font-bold mb-3 md:mb-4 text-foreground">
              My Tasks
            </h2>
            {availableTasks.map((task) => (
              <div
                key={task.id}
                className="mb-3 md:mb-4 flex flex-row justify-between items-center p-4 md:p-5 bg-card rounded-lg"
              >
                <div>
                  <h4 className="text-sm md:text-base text-foreground">
                    {task.name}
                  </h4>
                  <h5 className="text-xs md:text-sm text-primary">
                    +{task.points} DP
                  </h5>
                </div>
                {task.name?.toLowerCase().includes("story") ? (
                  <Button
                    size="sm"
                    onClick={() => postStory(task)}
                    disabled={storyBonusClaimed || loadingTasks[task.id]}
                    className={storyBonusClaimed ? "bg-gray-300" : ""}
                  >
                    {storyBonusClaimed ? "Claimed" : "Post Story"}
                  </Button>
                ) : (
                  !task.is_started && (
                    <Button
                      size="sm"
                      onClick={() => handleStartTask(task, navigate)}
                    >
                      Start
                    </Button>
                  )
                )}
                {task.is_started && !task.is_done && (
                  <Button
                    onClick={() => checkTaskHandler(task)}
                    size="sm"
                    disabled={loadingTasks[task.id] || false}
                  >
                    {loadingTasks[task.id] ? (
                      <>
                        <ArrowClockwise className="mr-2 h-3 w-3 md:h-4 md:w-4 animate-spin" />
                        Check
                      </>
                    ) : (
                      <>Check</>
                    )}
                  </Button>
                )}
              </div>
            ))}
          </>
        )}
        {completedTasks.length > 0 && (
          <>
            <h2 className="text-lg md:text-xl font-bold mt-6 mb-3 md:mb-4 text-foreground">
              My Rewards
            </h2>
            {completedTasks.map((task) => (
              <div
                key={task.id}
                className="mb-3 md:mb-4 flex flex-row justify-between items-center p-4 md:p-5 bg-card rounded-lg"
              >
                <h4 className="text-sm md:text-base line-through text-muted-foreground">
                  {task.name}
                </h4>
                <h5 className="text-xs md:text-sm text-primary font-bold">
                  +{task.points} DP
                </h5>
              </div>
            ))}
          </>
        )}
      </>
    )}
  </TabsContent>
);

const InviteFriends = () => (
  <TabsContent value="friends">
    <Card className="mt-4 px-4 py-4">
      <Button
        className="w-full text-xl"
        size={"lg"}
        onClick={() => handleShareTelegramReferralLink()}
      >
        <UsersThree size={24} className="mr-2" />
        Invite Friends
      </Button>
      <p className="mt-2 text-muted-foreground">
        Invite your friends to join Bridge23 and earn 1,000 Devotion Points 🙏
        Earn 20% of your referred friends' points.
      </p>
    </Card>
  </TabsContent>
);

export default function TasksPage() {
  const [isTma, setIsTma] = useState(false);
  const { data: profile } = useProfileQuery();
  const navigate = useNavigate();
  const [loadingTasks, setLoadingTasks] = useState<{ [key: number]: boolean }>(
    {}
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { startAnalysis, isLoading, isAnalyzing, error } = useAnalyzeReceipt();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { postStory, storyBonusClaimed } = useShareStory();

  // useQuery hook to fetch tasks, rewards and profile stats
  const { data: tasks, isPending: tasksLoading } = useQuery({
    queryKey: ["tasks"],
    queryFn: getTasks,
    enabled: !!profile,
  });

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
    enabled: !!profile,
  });

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  useEffect(() => {
    async function detectAndSetIsTMA() {
      const _isTMA = await isTMA();
      setIsTma(_isTMA);
    }

    detectAndSetIsTMA();
  }, []);

  const availableTasks = tasks ? tasks.filter((task) => !task.is_done) : [];
  const completedTasks = tasks ? tasks.filter((task) => task.is_done) : [];

  const claimedTierIds = new Set<number>(completedTasks.map((task) => task.id));
  const nextUnclaimedTier = tiers.find(
    (tier) => !claimedTierIds.has(tier.taskId)
  );

  const totalUploads = profileStats?.total_items || 0;
  const hasReachedUploads = nextUnclaimedTier
    ? totalUploads >= nextUnclaimedTier.uploads
    : false;

  const progress = nextUnclaimedTier
    ? (totalUploads / nextUnclaimedTier.uploads) * 100
    : 100;
  const progressWidth = useSpring({
    width: `${Math.min(progress, 100)}%`,
    from: { width: "0%" },
  });

  const checkTaskHandler = async (task: Task) => {
    setLoadingTasks((prev) => ({ ...prev, [task.id]: true }));
    try {
      const completed = await handleCheckTask(task);
      if (completed) {
        toast({
          title: "Task completed!",
          description: "You have completed the task",
        });
      } else {
        toast({
          title: "Error completing task",
          description: "There was an error completing the task",
        });
      }
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      queryClient.invalidateQueries({ queryKey: ["rewards"] });
    } catch (e) {
      console.error("Error checking task", e);
      toast({
        title: "Error checking task",
        description: (e as Error).message,
      });
    } finally {
      setLoadingTasks((prev) => ({ ...prev, [task.id]: false }));
    }
  };

  const startTaskHandler = async (task: Task) => {
    await handleStartTask(task, navigate, (error) => {
      toast({
        title: "Error starting task",
        description: error,
      });
    });
    queryClient.invalidateQueries({ queryKey: ["tasks"] });
  };

  const handleFileUpload = (file: File) => {
    startAnalysis({
      target: { files: [file] },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const claimPointsMutation = useMutation({
    mutationFn: async () => {
      const tierToClaim = nextUnclaimedTier;
      if (!tierToClaim) throw new Error("No tier available");
      await handleStartTask(
        { id: tierToClaim.taskId } as Task,
        navigate,
        (error) => {
          toast({
            title: "Error starting task",
            description: error,
          });
        }
      );
      await handleCheckTask({ id: tierToClaim.taskId } as Task);
    },
    onSuccess: () => {
      toast({
        title: "Points claimed!",
        description: `You have claimed ${nextUnclaimedTier?.points} points`,
      });
      // Invalidate queries to refetch updated data
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      queryClient.invalidateQueries({ queryKey: ["rewards"] });
    },
    onError: (error: Error) => {
      toast({
        title: "Error claiming points",
        description: error.message,
      });
    },
  });

  const handleClaimPointsClick = () => {
    claimPointsMutation.mutate();
  };

  return (
    <PageWrapper>
      <div className="flex flex-col items-center justify-center text-center w-full">
        <h2 className="text-3xl md:text-4xl font-extrabold text-foreground">
          {formatNumber(rewards?.total_points || 0)} 🙏
        </h2>
        <h3 className="text-sm md:text-base text-muted-foreground mt-2">
          Devotion Points
        </h3>
        <a
          href="https://bridge23inc.gitbook.io/data-miner-guide"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary mt-2 text-sm md:text-base hover:text-primary/80 hover:underline transition-colors duration-200"
        >
          How it works?
        </a>
      </div>

      {nextUnclaimedTier && (
        <div className="mt-6 md:mt-8">
          <MainQuest
            totalUploads={totalUploads}
            progress={{ width: progressWidth.width }}
            handleUploadClick={handleUploadClick}
            fileInputRef={fileInputRef}
            handleFileUpload={handleFileUpload}
            isLoading={isLoading}
            isAnalyzing={isAnalyzing}
            uploadSuccess={false}
            error={error}
            handleClaimPointsClick={handleClaimPointsClick}
            nextTier={nextUnclaimedTier}
            hasReachedUploads={hasReachedUploads}
          />
        </div>
      )}

      {isTma && (
        <div className="mt-6 md:mt-8">
          <Tabs defaultValue="tasks" className="w-full">
            <TabsList className="w-full grid grid-cols-2 bg-muted/50 rounded-lg p-1">
              <TabsTrigger
                value="tasks"
                className="rounded-md text-sm ring-offset-background transition-all hover:bg-background hover:text-foreground data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
              >
                Complete Tasks
              </TabsTrigger>
              {isTma && (
                <TabsTrigger
                  value="friends"
                  className="rounded-md text-sm ring-offset-background transition-all hover:bg-background hover:text-foreground data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
                >
                  Invite Friends
                </TabsTrigger>
              )}
            </TabsList>

            <div className="mt-4 md:mt-6">
              <TaskList
                tasksLoading={tasksLoading}
                availableTasks={availableTasks.filter(
                  (task) => task.is_visible
                )}
                completedTasks={completedTasks}
                handleStartTask={startTaskHandler}
                checkTaskHandler={checkTaskHandler}
                loadingTasks={loadingTasks}
                navigate={navigate}
                postStory={postStory}
                storyBonusClaimed={storyBonusClaimed}
              />
              {isTma && <InviteFriends />}
            </div>
          </Tabs>
        </div>
      )}
    </PageWrapper>
  );
}
